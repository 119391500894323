import React from "react";
import CKEditors from "react-ckeditor-component";

const RichTextBox = ({ setContent, content }) => {
  return (
    <CKEditors
      activeclassName="p10"
      content={content}
      events={{
        change: setContent,
      }}
    />
  );
};

export default RichTextBox;
