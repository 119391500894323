import { 
        ADD_CONTACTS
    } from "../actionTypes";
import ConfigDB from '../../data/contacts/config';

const initial_state = {
    contacts: ConfigDB.data
};

export default (state = initial_state, action) => {
    switch (action.type) {

        case ADD_CONTACTS:
            return { ...state, loading: false, contacts: action.data };

        default: return { ...state };
    }
}
