import React, { Fragment, useState, useEffect } from "react";
import { uuid } from "uuidv4";
import Dropzone from "react-dropzone-uploader";
import { useDispatch } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import imageCompression from "browser-image-compression";
import { uploadMultipleFiles } from "../../../services/userService";
import { setUploadedStatus } from "../../../state/actions/employees";
import {
  subscriberRegistrationNo,
  multipleImageFormat,
  multipleOthersFormat,
} from "../../../config.json";
import "./addphotos.css";

const Dropzones = ({ categoryI, categoryII, categoryIII, fileType }) => {
  const [progressValue, setProgressValue] = useState(0);
  const dispatch = useDispatch();
  const getUploadParams = ({ meta }) => {
    return { url: "https://httpbin.org/post" };
  };
  const onHandlerUplaod = async (compressedFile, ext) => {
    const multipleFormat =
      fileType === "IMG"
        ? multipleImageFormat
        : `${multipleOthersFormat}${ext}`;
    const guid = uuid();
    try {
      setProgressValue(50);
      const responseUpload = await uploadMultipleFiles(
        compressedFile,
        {
          registrationno: guid,
          categoryI,
          categoryII,
          categoryIII,
          parentregistrationno: subscriberRegistrationNo,
          fileType,
          multipleFormat,
        },
        dispatch
      );
      setProgressValue(100);
      if (responseUpload) {
        console.log("Uploaded successfully");
      }
    } catch (ex) {
      console.log("Uploaded issue please re-upload");
    }
  };

  const onChangeHandlerPics = async (imageFile) => {
    dispatch(setUploadedStatus(false));
    var options = {
      maxSizeMB: 500 / 1024,
      maxWidthOrHeight: 1280,
      useWebWorker: true,
    };
    try {
      const compressedFile = await imageCompression(imageFile, options);
      setProgressValue(25);
      await onHandlerUplaod(compressedFile, ""); // write your own logic
    } catch (error) {
      console.log(error);
    }
  };

  const onChangeHandlerOthers = async (imageFile) => {
    const re = /(?:\.([^.]+))?$/;
    const ext = re.exec(imageFile.name)[1];
    dispatch(setUploadedStatus(false));
    try {
      if (ext.toUpperCase().indexOf(fileType.toUpperCase()) !== -1) {
        if (ext.toUpperCase() !== "PPT") await onHandlerUplaod(imageFile, ext);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const handleSubmit = async (files, allFiles) => {
    allFiles.forEach((f) => {
      fileType === "IMG"
        ? onChangeHandlerPics(f.file)
        : onChangeHandlerOthers(f.file);
      f.remove();
    });
    toast.success("All files has been sucessfully uploaded!");
  };
  return (
    <Fragment>
      <Dropzone
        getUploadParams={getUploadParams}
        onSubmit={handleSubmit}
        inputContent="Drop Files (Custom Layout)"
      />
    </Fragment>
  );
};

export default Dropzones;
