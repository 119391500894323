import React, { Fragment } from 'react';
import Breadcrumb from '../../../layout/breadcrumb'
import {Container,Row,Col,Card,CardHeader,CardBody} from 'reactstrap';
import EditableGridView from '../../common/editablegridview';

const  Sample = (props) => {
    return (
         <Fragment>
         <Breadcrumb parent="Dashboard" title="Top Menu"/>
         
          <Container fluid={true}>
            <Row>
              <Col lg="8">
                <Card>
                  
                  <CardBody>
                    <EditableGridView />
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>   
         </Fragment> 
    );
}

export default Sample;