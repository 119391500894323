/* CUSTOMIZER */
export const ADD_COSTOMIZER = "ADD_COSTOMIZER";
export const ADD_LAYOUT = "ADD_LAYOUT";
export const ADD_SIDEBAR_TYPES = "ADD_SIDEBAR_TYPES";
export const ADD_SIDEBAR_SETTINGS = "ADD_SIDEBAR_SETTINGS";
export const ADD_COLOR = "ADD_COLOR";
export const ADD_MIX_BACKGROUND_LAYOUT = "ADD_MIX_BACKGROUND_LAYOUT";
export const ROUTER_ANIMATION = "ROUTER_ANIMATION";

/* ADD_CONTACTS */
export const ADD_CONTACTS = "ADD_CONTACTS";

/* SET_CATEGORIES */
export const SET_CATEGORIES = "SET_CATEGORIES";

/* SET_EMPLOYEES */
export const SET_EMPLOYEES = "SET_EMPLOYEES";
export const SET_SELECTED_EMPLOYEE = "SET_SELECTED_EMPLOYEE";
export const SET_SELECTED_EMPLOYEE_PICS = "SET_SELECTED_EMPLOYEE_PICS";
export const SET_SELECTED_BANNER_PICS_I = "SET_SELECTED_BANNER_PICS_I";
export const SET_SELECTED_BANNER_PICS_II = "SET_SELECTED_BANNER_PICS_II";
export const SET_UPLOADED_STATUS = "SET_UPLOADED_STATUS";

/* SET_GENDERS */
export const SET_GENDERS = "SET_GENDERS";

/* SET_MEMBERSHIPS */
export const SET_MEMBERSHIPS = "SET_MEMBERSHIPS";
export const SET_DESIGNATIONS = "SET_DESIGNATIONS";
export const SET_PREFIX = "SET_PREFIX";
export const SET_AFFAIRSTYPE = "SET_AFFAIRSTYPE";
