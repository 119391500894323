import React, { Fragment, useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import imageCompression from "browser-image-compression";
import { uploadFiles, create_UUID } from "../../services/userService";
import { uuid } from "uuidv4";
import { Button, Label, Input } from "reactstrap";
import { ToastContainer, toast } from "react-toastify";
import {
  apiUrl,
  profileImageFormat,
  subscriberRegistrationNo,
  imagesRepoUrl,
} from "../../config.json";
import { setUploadedStatus } from "../../state/actions/employees";
import { getUploadedStatus } from "../../state/selectors/employees";
import "./UploadSingleFile.css";

export const UploadSingleFile = ({
  setSelectedEmployeeID,
  setProgressValue,
}) => {
  const fileInput = useRef(null);
  const dispatch = useDispatch();
  const selectorUploadStatus = useSelector(getUploadedStatus);
  const [loading, setLoading] = useState(false);
  const [selectedProfilePics, setSelectedProfilePics] = useState(null);
  const [didClickButton, setDidClickButton] = useState(false);
  const fileProfileSelected = (compressedFile) => {
    setLoading(false);
    setProgressValue(25);
  };

  const onHandlerUplaod = async (compressedFile) => {
    const guid = uuid();
    setSelectedEmployeeID(guid);
    const imgPath = `${imagesRepoUrl}uploads/${subscriberRegistrationNo}/profilepics/temp_${guid}/${subscriberRegistrationNo}_${guid}${profileImageFormat}`;
    setSelectedProfilePics(imgPath);
    try {
      setProgressValue(50);
      const responseUpload = await uploadFiles(
        compressedFile,
        {
          registrationno: guid,
        },
        dispatch
      );
      setProgressValue(100);
      console.log("console.log===>", responseUpload);
      toast.success("Files sucessfully uploaded !");
      if (responseUpload) {
        console.log("Uploaded successfully");
      }
    } catch (ex) {
      console.log("Uploaded issue please re-upload");
    }
  };
  const onChangeHandlerPics = async (event) => {
    const imageFile = event.target.files[0];

    console.log("imageFile=====>", imageFile);
    // setSelectedUploadPhoto(imageFile);
    dispatch(setUploadedStatus(false));
    var options = {
      maxSizeMB: 12 / 1024,
      maxWidthOrHeight: 280,
      useWebWorker: true,
    };
    try {
      const compressedFile = await imageCompression(imageFile, options);
      await fileProfileSelected(compressedFile);
      await onHandlerUplaod(compressedFile); // write your own logic
    } catch (error) {
      console.log(error);
    }
  };
  const buttonClickHandler = () => {
    setDidClickButton(true);
  };
  const handleReloadImage = async () => {
    fileInput.current.click();
  };

  useEffect(() => {
    if (selectorUploadStatus) {
      setTimeout(() => {
        setLoading(true);
        console.log("selectorUploadStatus>>>>>>>>>>>>>>", selectorUploadStatus);
      }, 2000);
    }
  }, [selectorUploadStatus]);
  useEffect(() => {
    if (didClickButton) {
      fileInput.current.click();
    }
  }, [didClickButton]);
  const sectionStyle = {
    backgroundImage: `url(${selectedProfilePics})`,
  };
  return (
    <Fragment>
      <div style={{ display: "none" }}>
        <div className="custom-file">
          <input
            name="file"
            type="file"
            // className="custom-file-input"
            id="validatedCustomFile"
            accept="image/*"
            onChange={onChangeHandlerPics}
            ref={fileInput}
          />
        </div>
        <Label className="custom-file-label" htmlFor="validatedCustomFile">
          {"Choose file..."}
        </Label>
        <div className="invalid-feedback">
          {"Example invalid custom file feedback"}
        </div>
      </div>
      <Fragment>
        <div>
          {!loading ? (
            <div className="form-group">
              <Button
                variant="contained"
                color="primary"
                onClick={() => fileInput.current.click()}
              >
                Pick up profile photo
              </Button>
            </div>
          ) : (
            <div
              className="employeecard-image"
              onClick={() => handleReloadImage()}
              style={sectionStyle}
            ></div>
          )}
        </div>
      </Fragment>
    </Fragment>
  );
};
