import React, { Fragment, useState, useEffect } from 'react';
import Breadcrumb from '../../../layout/breadcrumb'
import {Container,Row,Col,Card,CardHeader,CardBody, Button} from 'reactstrap';
import { AgGridColumn, AgGridReact } from 'ag-grid-react';
import { useDispatch, useSelector } from 'react-redux';
import 'ag-grid-enterprise';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import {
  ADD_CONTACTS
} from '../../../state/redux/actionTypes';
import ConfigDB from '../../../state/data/contacts/configupdated';
import {getMultipleLevelCategory} from '../../../services/subscriberService';
import {getAllCategories} from "../../../state/selectors/categories";

const  Sample = (props) => {
  const [gridApi, setGridApi] = useState(null);
  const [gridColumnApi, setGridColumnApi] = useState(null);
  const [menuData, setMenuData] = useState([]);
  const [superMenuData, setSuperMenuData] = useState([]);
  const configDB = useSelector(content => content.Contacts.contacts);
  const dispatch = useDispatch();
    const [rowData, setRowData] = useState([
        { make: "Adani", model: "Caati", price: 35000, year: 2003 }
    ]);
    const [rowDataDepartment, setRowDataDepartment] = useState([]);
    function onGridReady(params) {
        setGridApi(params.api);
        setGridColumnApi(params.columnApi);
    }
    const handleUpdateData = () => {
      dispatch({ type: ADD_CONTACTS, data: ConfigDB.data });
    }
    const handleAddNewData = () => {
      console.log("Add new logic here");
    }
    const handleSaveData = () => {
      console.log("Save logic here");
    }
    const result = useSelector(getAllCategories);

    const getMenus = async () => {
      // const result =  await getMultipleLevelCategory(subscriberRegistrationNo);
      
      console.log("Categories===>", result);
      // await setMenuData(result.data[0]);
      // const filteredSuperMenu = result.data[0].filter(
      //   (item) => item.ParentId === null
      // );
      // setSuperMenuData(filteredSuperMenu);
    }
    useEffect(() => {
      getMenus();
      setRowData(configDB.details);
  }, [configDB]);
    useEffect(() => {
     console.log("Super Menu----->", superMenuData);
     setRowDataDepartment(superMenuData);
  }, [superMenuData]);
    return (
         <Fragment>
         <Breadcrumb parent="Dashboard" title="Default"/>
          <Container fluid={true}>
            <Row>
            <Col sm="12">
              <Card>
              <Button color="primary" className="btn-block btn-mail text-center mb-0 mt-0" onClick={handleUpdateData}>
                 <i className="fa fa-paper-plane mr-2"></i>{"Update Data"}
              </Button>
              </Card>
            </Col>
            </Row>
            <Row>
              <Col sm="12">
                <Card>
                 <CardBody>
                  <div className="ag-theme-alpine" style={{ height: 400, width: 800 }}>
                      <AgGridReact
                          onGridReady={onGridReady}
                          rowData={rowData}>
                          <AgGridColumn field="make" sortable={ true } filter={ true }></AgGridColumn>
                          <AgGridColumn field="model" sortable={ true } filter={ true }></AgGridColumn>
                          <AgGridColumn field="price" sortable={ true }></AgGridColumn>
                          <AgGridColumn field="year" sortable={ true }></AgGridColumn>
                      </AgGridReact>
                  </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
            <Row>
            <Col sm="2">
              <Card>
              <Button color="primary" className="btn-block btn-mail text-center mb-0 mt-0" onClick={handleSaveData}>
                 <i className="fa fa-paper-plane mr-2"></i>{"Save"}
              </Button>
              </Card>
            </Col>
            <Col sm="2"></Col>
            <Col sm="2">
              <Card>
              <Button color="primary" className="btn-block btn-mail text-center mb-0 mt-0" onClick={handleAddNewData}>
                 <i className="fa fa-paper-plane mr-2"></i>{"Add New"}
              </Button>
              </Card>
            </Col>
            <Col sm="6"></Col>
            </Row>
            <Row>
              <Col sm="12">
                <Card>
                 <CardBody>
                  <div className="ag-theme-alpine" style={{ height: 400, width: 800 }}>
                      <AgGridReact
                          onGridReady={onGridReady}
                          rowData={rowDataDepartment}>
                          <AgGridColumn field="CategoryId" sortable={ true } filter={ true }></AgGridColumn>
                          <AgGridColumn field="CategoryName" sortable={ true } filter={ true }></AgGridColumn>
                          <AgGridColumn field="ParentCategoryName" sortable={ true }></AgGridColumn>
                      </AgGridReact>
                  </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>   
          </Fragment> 
    );
}

export default Sample;