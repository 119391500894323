import React, { Fragment, useState, useEffect, useRef } from "react";
import { useHistory } from "react-router-dom";
import Breadcrumb from "../../../../layout/breadcrumb";
import { useSelector, useDispatch } from "react-redux";
import { useForm } from "react-hook-form";
import { uuid } from "uuidv4";
import { AccordianComponentWithTitle } from "../accordianComponentWithTitle";
import { Accordion } from "react-bootstrap";
import "react-datepicker/dist/react-datepicker.css";
import {
  Container,
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  Button,
  Form,
  FormGroup,
  Label,
  Input,
} from "reactstrap";
import { HomeLevelDetailsTitle } from "../../../../constant";
import {
  getEmployees,
  addHomeLevelDetails,
} from "../../../../services/subscriberService";
import { getAllPrefix } from "../../../../state/selectors/prefix";
import { getAllCategories } from "../../../../state/selectors/categories";
import {
  setSelctedBannerPictureI,
  setSelctedBannerPictureII,
} from "../../../../state/actions/employees";
import {
  getSelectedBannerPictureI,
  getSelectedBannerPictureII,
} from "../../../../state/selectors/employees";
import {
  subscriberRegistrationNo,
  profileImageFormat,
} from "../../../../config.json";
import { UploadSingleFile } from "../../../common/UploadSingleFile";
import { UploadBannerSingleFileUpdate } from "../../../common/UploadBannerSingleFileUpdate";

export const HomeleveldetailsAdd = () => {
  const dispatch = useDispatch();
  const selectorBannerPictureI = useSelector(getSelectedBannerPictureI);
  const selectorBannerPictureII = useSelector(getSelectedBannerPictureII);
  const { register, handleSubmit, errors } = useForm();
  const [progressValue, setProgressValue] = useState();
  const [contentSectionDetails1, setContentSectionDetails1] = useState("");
  const [contentSectionDetails2, setContentSectionDetails2] = useState("");
  const [contentSectionDetails3, setContentSectionDetails3] = useState("");
  const [contentSectionDetails4, setContentSectionDetails4] = useState("");
  const [contentSectionDetails5, setContentSectionDetails5] = useState("");
  const [contentSectionDetails6, setContentSectionDetails6] = useState("");
  const [contentSectionDetails7, setContentSectionDetails7] = useState("");

  const [savedSuccess, setSavedSuccess] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [homepageId, setHomepageId] = useState(0);
  const [selectedCategoryI, setSelectedCategoryI] = useState(0);
  const [selectedCategoryII, setSelectedCategoryII] = useState(0);
  const [selectedCategoryIII, setSelectedCategoryIII] = useState(0);
  const [selectedBanner1, setSelectedBanner1] = useState("");
  const [selectedBanner2, setSelectedBanner2] = useState("");
  const [selectedEmployeeID, setSelectedEmployeeID] = useState("");
  const [profileid, setProfileid] = useState();
  const [selectedMainTitle, setSelectedMainTitle] = useState(0);
  const [selectedSectionTitle1, setSelectedSectionTitle1] = useState("");
  const [selectedSectionTitle2, setSelectedSectionTitle2] = useState("");
  const [selectedSectionTitle3, setSelectedSectionTitle3] = useState("");
  const [selectedSectionTitle4, setSelectedSectionTitle4] = useState("");
  const [selectedSectionTitle5, setSelectedSectionTitle5] = useState("");
  const [selectedSectionTitle6, setSelectedSectionTitle6] = useState("");
  const [selectedSectionTitle7, setSelectedSectionTitle7] = useState("");
  const [dataCategoryI, setDataCategoryI] = useState([]);
  const [dataCategoryII, setDataCategoryII] = useState([]);
  const [dataCategoryIII, setDataCategoryIII] = useState([]);
  const [dataSelectedEmployees, setDataSelectedEmployees] = useState([]);
  const [name, setName] = useState([]);
  const allCategories = useSelector(getAllCategories);
  const history = useHistory();
  const onSubmit = (data) => {
    console.log("data submit====>", data);
    if (data !== "") {
      alert("You submitted the form and stuff!");
    } else {
      errors.showMessages();
    }
  };
  const handleChangeCategoryI = (e) => {
    const filteredCategories =
      allCategories &&
      allCategories.filter((item) => item.ParentId == e.target.value);
    setDataCategoryII(filteredCategories);
    setSelectedCategoryI(e.target.value);
    setSelectedCategoryII(0);
    setSelectedCategoryIII(0);
  };
  const handleChangeCategoryII = (e) => {
    const filteredCategories =
      allCategories &&
      allCategories.filter((item) => item.ParentId == e.target.value);
    setDataCategoryIII(filteredCategories);
    setSelectedCategoryII(e.target.value);
    setSelectedCategoryIII(0);
  };

  const setEmployeeDetails = async (catId) => {
    const employeesResult = await getEmployees(
      catId,
      subscriberRegistrationNo,
      ""
    );
    setDataSelectedEmployees(employeesResult.data[0]);
  };
  const handleChangeCategoryIII = (e) => {
    setEmployeeDetails(e.target.value);
    setSelectedCategoryIII(e.target.value);
  };
  const handleChangeManager = (e) => {
    setProfileid(e.target.value);
  };

  const saveEmployeeData = async (buildRecords) => {
    console.log("buildRecords=====>", buildRecords);
    const result = await addHomeLevelDetails(
      buildRecords,
      `${subscriberRegistrationNo}_${selectedEmployeeID}${profileImageFormat}`
    );
    if (result.status === 200) {
      setSavedSuccess(true);
      history.push("/");
    }
  };
  const formatMailProductsList = (selectedProducts) => {
    let prodList = [];
    selectedProducts.forEach((element) => {
      prodList.push(element.label);
    });
    return prodList.join().replace(/,/g, ", ");
  };
  const handleSubmitData = (e) => {
    const prodToList = formatMailProductsList(name);
    const records = [
      {
        id: homepageId,
        registrationno: subscriberRegistrationNo,
        secdetails1: contentSectionDetails1,
        secdetails2: contentSectionDetails2,
        secdetails3: contentSectionDetails3,
        secdetails4: contentSectionDetails4,
        secdetails5: contentSectionDetails5,
        secdetails6: contentSectionDetails6,
        secdetails7: contentSectionDetails7,
        categorylevel1: selectedCategoryI,
        categorylevel2: selectedCategoryII,
        categorylevel3: selectedCategoryIII,
        profileid,
        maintitle: selectedMainTitle,
        sectitle1: selectedSectionTitle1,
        sectitle2: selectedSectionTitle2,
        sectitle3: selectedSectionTitle3,
        sectitle4: selectedSectionTitle4,
        sectitle5: selectedSectionTitle5,
        sectitle6: selectedSectionTitle6,
        sectitle7: selectedSectionTitle7,
        bannerurl1: selectedBanner1,
        bannerurl2: selectedBanner2,
      },
    ];
    if (selectedSectionTitle1 !== "" || selectedBanner1 !== "") {
      const buildRecords = JSON.stringify(records).replace(/"/g, '"');
      saveEmployeeData(buildRecords);
    }
    e.preventDefault();
  };
  const handleChangeMainTitle = (e) => {
    setSelectedMainTitle(e.target.value);
  };
  const handleChangeSectionTitle1 = (e) => {
    setSelectedSectionTitle1(e.target.value);
  };
  const handleChangeSectionTitle2 = (e) => {
    setSelectedSectionTitle2(e.target.value);
  };
  const handleChangeSectionTitle3 = (e) => {
    setSelectedSectionTitle3(e.target.value);
  };
  const handleChangeSectionTitle4 = (e) => {
    setSelectedSectionTitle4(e.target.value);
  };
  const handleChangeSectionTitle5 = (e) => {
    setSelectedSectionTitle5(e.target.value);
  };
  const handleChangeSectionTitle6 = (e) => {
    setSelectedSectionTitle6(e.target.value);
  };
  const handleChangeSectionTitle7 = (e) => {
    setSelectedSectionTitle7(e.target.value);
  };

  const handleContentSectionDetails1 = (evt) => {
    const newContent = evt.editor.getData();
    setContentSectionDetails1(newContent);
  };
  const handleContentSectionDetails2 = (evt) => {
    const newContent = evt.editor.getData();
    setContentSectionDetails2(newContent);
  };
  const handleContentSectionDetails3 = (evt) => {
    const newContent = evt.editor.getData();
    setContentSectionDetails3(newContent);
  };
  const handleContentSectionDetails4 = (evt) => {
    const newContent = evt.editor.getData();
    setContentSectionDetails4(newContent);
  };
  const handleContentSectionDetails5 = (evt) => {
    const newContent = evt.editor.getData();
    setContentSectionDetails5(newContent);
  };
  const handleContentSectionDetails6 = (evt) => {
    const newContent = evt.editor.getData();
    setContentSectionDetails6(newContent);
  };
  const handleContentSectionDetails7 = (evt) => {
    const newContent = evt.editor.getData();
    setContentSectionDetails7(newContent);
  };
  const handleSetSelctedBannerPictureI = (fileNameNew) => {
    dispatch(setSelctedBannerPictureI(fileNameNew));
  };
  const handleSetSelctedBannerPictureII = (fileNameNew) => {
    dispatch(setSelctedBannerPictureII(fileNameNew));
  };
  useEffect(() => {
    const filteredCategories =
      allCategories &&
      allCategories.filter(
        (item) => item.ParentId === null || item.ParentId === 0
      );
    setDataCategoryI(filteredCategories);
  }, [allCategories]);
  useEffect(() => {
    setSelectedBanner1(selectorBannerPictureI);
  }, [selectorBannerPictureI]);
  useEffect(() => {
    setSelectedBanner2(selectorBannerPictureII);
  }, [selectorBannerPictureII]);
  useEffect(() => {
    setHomepageId(uuid());
  }, []);
  return (
    <Fragment>
      <Breadcrumb parent="Form" />
      {savedSuccess ? (
        <p>Employee has been added successfully!</p>
      ) : (
        <Container fluid={true}>
          <Row>
            <Col sm="12">
              <Card>
                <CardHeader>
                  <h5>{HomeLevelDetailsTitle}</h5>
                </CardHeader>
                <CardBody>
                  <Form
                    className="needs-validation"
                    noValidate=""
                    onSubmit={handleSubmitData}
                  >
                    <Row>
                      <Col md="4 mb-3">
                        <FormGroup>
                          <Label>Category I</Label>
                          <Input
                            type="select"
                            className="custom-select"
                            required=""
                            value={selectedCategoryI}
                            onChange={handleChangeCategoryI}
                          >
                            <option value="0">{"Select..."}</option>
                            {dataCategoryI &&
                              dataCategoryI.length > 0 &&
                              dataCategoryI.map((category) => {
                                return (
                                  <option value={category.CategoryId}>
                                    {category.CategoryName}
                                  </option>
                                );
                              })}
                          </Input>
                          <div className="invalid-feedback">
                            {"Example invalid custom select feedback"}
                          </div>
                        </FormGroup>
                      </Col>
                      <Col md="4 mb-3">
                        <FormGroup>
                          <Label>Category II</Label>
                          <Input
                            type="select"
                            className="custom-select"
                            required=""
                            value={selectedCategoryII}
                            onChange={handleChangeCategoryII}
                          >
                            <option value="0">{"Select..."}</option>
                            {dataCategoryII &&
                              dataCategoryII.length > 0 &&
                              dataCategoryII.map((category) => {
                                return (
                                  <option value={category.CategoryId}>
                                    {category.CategoryName}
                                  </option>
                                );
                              })}
                          </Input>
                          <div className="invalid-feedback">
                            {"Example invalid custom select feedback"}
                          </div>
                        </FormGroup>
                      </Col>
                      <Col md="4 mb-3">
                        <FormGroup>
                          <Label>Category III</Label>
                          <Input
                            type="select"
                            className="custom-select"
                            required=""
                            value={selectedCategoryIII}
                            onChange={handleChangeCategoryIII}
                          >
                            <option value="0">{"Select..."}</option>
                            {dataCategoryIII &&
                              dataCategoryIII.length > 0 &&
                              dataCategoryIII.map((category) => {
                                return (
                                  <option value={category.CategoryId}>
                                    {category.CategoryName}
                                  </option>
                                );
                              })}
                          </Input>
                          <div className="invalid-feedback">
                            {"Example invalid custom select feedback"}
                          </div>
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col md="8 mb-3">
                        <FormGroup>
                          <Label>Main Title</Label>
                          <Input
                            className="form-control"
                            name="mainTitle"
                            type="text"
                            placeholder="Main Title"
                            innerRef={register({ required: true })}
                            onBlur={handleChangeMainTitle}
                          />
                          <span>
                            {errors.mainTitle && "Main Title is required"}
                          </span>
                          <div className="valid-feedback">{"Looks good!"}</div>
                        </FormGroup>
                      </Col>

                      <Col md="4 mb-3">
                        <FormGroup>
                          <Label>Department Head</Label>
                          <Input
                            type="select"
                            className="custom-select"
                            required=""
                            value={profileid}
                            onChange={handleChangeManager}
                          >
                            <option value="0">{"Select..."}</option>
                            {dataSelectedEmployees &&
                              dataSelectedEmployees.length > 0 &&
                              dataSelectedEmployees.map((emp) => {
                                return (
                                  <option
                                    value={emp.EmpID}
                                  >{`${emp.prefix} ${emp.FirstName} ${emp.LastName}`}</option>
                                );
                              })}
                          </Input>
                          <div className="invalid-feedback">
                            {"Example invalid custom select feedback"}
                          </div>
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col md="6 mb-3">
                        <UploadBannerSingleFileUpdate
                          selectedFieldID={homepageId}
                          setProgressValue={setProgressValue}
                          selectedLabel="I"
                          getSelectedBannerPicture={getSelectedBannerPictureI}
                          setSelctedBannerPicture={
                            handleSetSelctedBannerPictureI
                          }
                        />
                      </Col>
                      <Col md="6 mb-3">
                        <UploadBannerSingleFileUpdate
                          selectedFieldID={homepageId}
                          setProgressValue={setProgressValue}
                          selectedLabel="II"
                          getSelectedBannerPicture={getSelectedBannerPictureII}
                          setSelctedBannerPicture={
                            handleSetSelctedBannerPictureII
                          }
                        />
                      </Col>
                    </Row>

                    <Col md="12 mb-3">
                      <Accordion defaultActiveKey="0">
                        <div
                          className="default-according style-1"
                          id="accordionoc"
                        >
                          <AccordianComponentWithTitle
                            setContentSectionDetails1={
                              handleContentSectionDetails1
                            }
                            setContentSectionDetails2={
                              handleContentSectionDetails2
                            }
                            setContentSectionDetails3={
                              handleContentSectionDetails3
                            }
                            setContentSectionDetails4={
                              handleContentSectionDetails4
                            }
                            setContentSectionDetails5={
                              handleContentSectionDetails5
                            }
                            setContentSectionDetails6={
                              handleContentSectionDetails6
                            }
                            setContentSectionDetails7={
                              handleContentSectionDetails7
                            }
                            contentSectionDetails1={contentSectionDetails1}
                            contentSectionDetails2={contentSectionDetails2}
                            contentSectionDetails3={contentSectionDetails3}
                            contentSectionDetails4={contentSectionDetails4}
                            contentSectionDetails5={contentSectionDetails5}
                            contentSectionDetails6={contentSectionDetails6}
                            contentSectionDetails7={contentSectionDetails7}
                            setChangeSectionTitle1={handleChangeSectionTitle1}
                            setChangeSectionTitle2={handleChangeSectionTitle2}
                            setChangeSectionTitle3={handleChangeSectionTitle3}
                            setChangeSectionTitle4={handleChangeSectionTitle4}
                            setChangeSectionTitle5={handleChangeSectionTitle5}
                            setChangeSectionTitle6={handleChangeSectionTitle6}
                            setChangeSectionTitle7={handleChangeSectionTitle7}
                          />
                        </div>
                      </Accordion>
                    </Col>
                    <Button color="primary">
                      <center>{"Submit form"}</center>
                    </Button>
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      )}
    </Fragment>
  );
};
