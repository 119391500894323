export const setEmployees = (data) => ({
  type: "SET_EMPLOYEES",
  data,
});

export const setSelctedEmployee = (data) => ({
  type: "SET_SELECTED_EMPLOYEE",
  data,
});

export const setSelctedEmployeePicture = (data) => ({
  type: "SET_SELECTED_EMPLOYEE_PICS",
  data,
});

export const setSelctedBannerPictureI = (data) => ({
  type: "SET_SELECTED_BANNER_PICS_I",
  data,
});

export const setSelctedBannerPictureII = (data) => ({
  type: "SET_SELECTED_BANNER_PICS_II",
  data,
});

export const setUploadedStatus = (data) => ({
  type: "SET_UPLOADED_STATUS",
  data,
});
