import { 
        SET_CATEGORIES
    } from "../actionTypes";

const initial_state = {
    categories: []
};

export default (state = initial_state, action) => {
    switch (action.type) {
        case SET_CATEGORIES:
            return { ...state, loading: false, categories: action.data };

        default: return { ...state };
    }
}
