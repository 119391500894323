import { 
    SET_MEMBERSHIPS
    } from "../actionTypes";

const initial_state = {
    memberships: []
};

export default (state = initial_state, action) => {
    switch (action.type) {
        case SET_MEMBERSHIPS:
            return { ...state, loading: false, memberships: action.data };

        default: return { ...state };
    }
}
