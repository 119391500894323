import React, { useState } from "react";
import { AgGridColumn, AgGridReact } from "ag-grid-react";

export const CommonDataGrid = ({
  allData,
  columnsData,
  handleSelectionChanged,
}) => {
  const [gridApi, setGridApi] = useState(null);
  const [gridColumnApi, setGridColumnApi] = useState(null);
  const [defaultColDef] = useState({
    flex: 1,
    minWidth: 100,
  });
  const [rowSelection] = useState("single");
  const onGridReady = (params) => {
    setGridApi(params.api);
    setGridColumnApi(params.columnApi);
  };

  return (
    <div className="ag-theme-alpine" style={{ height: 400, width: 1800 }}>
      <AgGridReact
        rowData={allData}
        onGridReady={onGridReady}
        rowSelection={rowSelection}
        onSelectionChanged={() =>
          handleSelectionChanged(gridApi.getSelectedRows())
        }
        defaultColDef={defaultColDef}
      >
        {columnsData.map((col) => (
          <AgGridColumn
            field={col}
            sortable={true}
            filter={true}
          ></AgGridColumn>
        ))}
      </AgGridReact>
    </div>
  );
};
