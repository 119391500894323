import React, { Fragment, useState } from "react";
import { Card, CardHeader, CardBody } from "reactstrap";
import RichTextBox from "../../dashboard/richtextbox/";
import { Accordion } from "react-bootstrap";

export const AccordionWithOpenandCloseIcon = ({
  setContentKeyResponsibilties,
  setContentBiography,
  setContentResearchAndPublications,
  setContentConference,
  setContentAboutMe,
  setContentPaperPresentation,
  contentKeyResponsibilties,
  contentBiography,
  contentResearchAndPublications,
  contentConference,
  contentAboutMe,
  contentPaperPresentation,
}) => {
  const [content, setContent] = useState("content");
  const [expanded1, setexpanded1] = useState(true);
  const [expanded2, setexpanded2] = useState(false);
  const [expanded3, setexpanded3] = useState(false);
  const [expanded4, setexpanded4] = useState(false);
  const [expanded5, setexpanded5] = useState(false);
  const [expanded6, setexpanded6] = useState(false);
  const Accordion1 = () => {
    setexpanded1(!expanded1);
    setexpanded2(false);
    setexpanded3(false);
    setexpanded4(false);
    setexpanded5(false);
    setexpanded6(false);
  };
  const Accordion2 = () => {
    setexpanded2(!expanded2);
    setexpanded1(false);
    setexpanded3(false);
    setexpanded4(false);
    setexpanded5(false);
    setexpanded6(false);
  };
  const Accordion3 = () => {
    setexpanded3(!expanded3);
    setexpanded2(false);
    setexpanded1(false);
    setexpanded4(false);
    setexpanded5(false);
    setexpanded6(false);
  };
  const Accordion4 = () => {
    setexpanded4(!expanded4);
    setexpanded1(false);
    setexpanded2(false);
    setexpanded3(false);
    setexpanded5(false);
    setexpanded6(false);
  };
  const Accordion5 = () => {
    setexpanded5(!expanded5);
    setexpanded1(false);
    setexpanded2(false);
    setexpanded3(false);
    setexpanded4(false);
    setexpanded6(false);
  };
  const Accordion6 = () => {
    setexpanded6(!expanded5);
    setexpanded1(false);
    setexpanded2(false);
    setexpanded3(false);
    setexpanded4(false);
    setexpanded5(false);
  };
  return (
    <Fragment>
      <Card>
        <CardHeader>
          <h5 className="mb-0">
            <Accordion.Toggle
              as={Card.Header}
              className="btn btn-link txt-white "
              color="primary"
              onClick={Accordion1}
              eventKey="1"
              aria-expanded={expanded1}
            >
              {"Key Responsibilties"}
            </Accordion.Toggle>
          </h5>
        </CardHeader>
        <Accordion.Collapse eventKey="1">
          <CardBody>
            <RichTextBox
              setContent={setContentKeyResponsibilties}
              content={contentKeyResponsibilties}
            />
          </CardBody>
        </Accordion.Collapse>
      </Card>
      <Card>
        <CardHeader>
          <h5 className="mb-0">
            <Accordion.Toggle
              as={Card.Header}
              className="btn btn-link txt-white "
              color="primary"
              onClick={Accordion2}
              eventKey="2"
              aria-expanded={expanded2}
            >
              {"Biography"}
            </Accordion.Toggle>
          </h5>
        </CardHeader>
        <Accordion.Collapse eventKey="2">
          <CardBody>
            <RichTextBox
              setContent={setContentBiography}
              content={contentBiography}
            />
          </CardBody>
        </Accordion.Collapse>
      </Card>
      <Card>
        <CardHeader>
          <h5 className="mb-0">
            <Accordion.Toggle
              as={Card.Header}
              className="btn btn-link txt-white "
              color="primary"
              onClick={Accordion3}
              eventKey="3"
              aria-expanded={expanded3}
            >
              {"Research and Publications"}
            </Accordion.Toggle>
          </h5>
        </CardHeader>
        <Accordion.Collapse eventKey="3">
          <CardBody>
            <RichTextBox
              setContent={setContentResearchAndPublications}
              content={contentResearchAndPublications}
            />
          </CardBody>
        </Accordion.Collapse>
      </Card>
      <Card>
        <CardHeader>
          <h5 className="mb-0">
            <Accordion.Toggle
              as={Card.Header}
              className="btn btn-link txt-white "
              color="primary"
              onClick={Accordion4}
              eventKey="4"
              aria-expanded={expanded4}
            >
              {"Conference"}
            </Accordion.Toggle>
          </h5>
        </CardHeader>
        <Accordion.Collapse eventKey="4">
          <CardBody>
            <RichTextBox
              setContent={setContentConference}
              content={contentConference}
            />
          </CardBody>
        </Accordion.Collapse>
      </Card>

      <Card>
        <CardHeader>
          <h5 className="mb-0">
            <Accordion.Toggle
              as={Card.Header}
              className="btn btn-link txt-white "
              color="primary"
              onClick={Accordion5}
              eventKey="5"
              aria-expanded={expanded5}
            >
              {"Paper Presentation"}
            </Accordion.Toggle>
          </h5>
        </CardHeader>
        <Accordion.Collapse eventKey="5">
          <CardBody>
            <RichTextBox
              setContent={setContentPaperPresentation}
              content={contentPaperPresentation}
            />
          </CardBody>
        </Accordion.Collapse>
      </Card>
      <Card>
        <CardHeader>
          <h5 className="mb-0">
            <Accordion.Toggle
              as={Card.Header}
              className="btn btn-link txt-white "
              color="primary"
              onClick={Accordion6}
              eventKey="6"
              aria-expanded={expanded6}
            >
              {"About Me"}
            </Accordion.Toggle>
          </h5>
        </CardHeader>
        <Accordion.Collapse eventKey="6">
          <CardBody>
            <RichTextBox
              setContent={setContentAboutMe}
              content={contentAboutMe}
            />
          </CardBody>
        </Accordion.Collapse>
      </Card>
    </Fragment>
  );
};
