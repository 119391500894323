import { 
    SET_GENDERS
    } from "../actionTypes";

const initial_state = {
    genders: []
};

export default (state = initial_state, action) => {
    switch (action.type) {
        case SET_GENDERS:
            return { ...state, loading: false, genders: action.data };

        default: return { ...state };
    }
}
