export const getAllEmployees = (state) => state.Employees.employees;

export const getSelectedEmployee = (state) => state.Employees.selectedemployee;

export const getSelectedemployeePicture = (state) =>
  state.Employees.selectedemployeePicture;

export const getSelectedBannerPictureI = (state) =>
  state.Employees.selectedBannerPictureI;

export const getSelectedBannerPictureII = (state) =>
  state.Employees.selectedBannerPictureII;

export const getUploadedStatus = (state) => state.Employees.uploadedstatus;

export const getAllFasionBrands = (state) =>
  state.employees.employees
    .map((employee) => employee.brand)
    .reduce(
      (unique, brand) => (unique.includes(brand) ? unique : [...unique, brand]),
      []
    )
    .sort((a, b) => {
      const textA = a.toUpperCase();
      const textB = b.toUpperCase();
      return textA < textB ? -1 : textA > textB ? 1 : 0;
    });

export const getFilteredEmployees = (state) => {
  const {
    employees: { employees },
    filters,
  } = state;
  return employees
    .filter((employee) => {
      const searchText = (
        employee.brand.toLowerCase() +
        " " +
        employee.name.toLowerCase()
      ).includes(filters.name.toLowerCase().trim());

      if (filters.brand.length > 0 && filters.name.length === 0) {
        return filters.brand.includes(employee.brand);
      } else if (filters.name.length !== 0 && filters.brand.length === 0) {
        return searchText;
      } else if (filters.name.length > 0 && filters.name.length > 0) {
        return filters.brand.includes(employee.brand) && searchText;
      } else {
        return employee;
      }
    })
    .sort((a, b) => {
      const textA = a.brand.toUpperCase();
      const textB = b.brand.toUpperCase();
      return textA < textB ? -1 : textA > textB ? 1 : 0;
    });
};
