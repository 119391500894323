import React, { Fragment , useState} from 'react';
import Breadcrumb from '../../../layout/breadcrumb'
import CKEditors from "react-ckeditor-component";
import {Container,Row,Col,Card,CardBody} from 'reactstrap'
import { CKEditorExample } from "../../../constant";



const Sample =  ({setContent,content}) =>  {
    // const onChange = (evt) => {
    //     const newContent = evt.editor.getData();
    //     setContent(newContent)
    // }
   
    return (
            <Fragment>
                <Container>
                    <Row>
                        <Col sm="12">
                            
                                    <CKEditors
                                        activeclassName="p10"
                                        content={content}
                                        events={{
                                            "change": setContent
                                        }}
                                    />
                                
                        </Col>
                    </Row>
                </Container>
            </Fragment>
        );
    }

export default Sample;