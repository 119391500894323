import React, { Fragment, useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import imageCompression from "browser-image-compression";
import {
  uploadbannerfilesupdate,
  create_UUID,
} from "../../services/userService";
import { Button, Label, Input } from "reactstrap";
import { ToastContainer, toast } from "react-toastify";
import {
  apiUrl,
  bannerImageFormat,
  subscriberRegistrationNo,
  imagesRepoUrl,
} from "../../config.json";
import { setUploadedStatus } from "../../state/actions/employees";
import { getUploadedStatus } from "../../state/selectors/employees";
import { useClearCache } from "react-clear-cache";
import "./UploadBannerSingleFileUpdate.css";

export const UploadBannerSingleFileUpdate = ({
  setProgressValue,
  selectedFieldID,
  selectedLabel,
  setSelctedBannerPicture,
  getSelectedBannerPicture,
}) => {
  const fileInput = useRef(null);
  const dispatch = useDispatch();
  const selectorUploadStatus = useSelector(getUploadedStatus);
  const selectorBannerPicture = useSelector(getSelectedBannerPicture);
  const [loading, setLoading] = useState(true);
  const [fileNameCount, setFileNameCount] = useState(0);
  const [selectedProfilePics, setSelectedProfilePics] = useState(null);
  const [selectedFilename, setSelectedFilename] = useState(null);
  const [didClickButton, setDidClickButton] = useState(false);
  const fileProfileSelected = (compressedFile) => {
    setLoading(false);
    setProgressValue(25);
  };

  const onHandlerUplaod = async (compressedFile) => {
    const filecount = Number(fileNameCount) + 1;
    const fileNameNew = `${subscriberRegistrationNo}_${selectedFieldID}${selectedLabel}_${filecount}${bannerImageFormat}`;
    const imgPathRelative = `uploads/${subscriberRegistrationNo}/bannerpics/${selectedFieldID}${selectedLabel}/${fileNameNew}`;
    try {
      setProgressValue(50);
      const responseUpload = await uploadbannerfilesupdate(
        compressedFile,
        {
          registrationno: selectedFieldID,
          filecount,
          selectedLabel,
        },
        dispatch
      );
      setProgressValue(100);
      console.log("console.log===>", responseUpload);
      toast.success("Files sucessfully uploaded !");
      // setSelectedProfilePics(imgPath);
      setSelctedBannerPicture(imgPathRelative);
      if (responseUpload) {
        console.log("Uploaded successfully");
      }
    } catch (ex) {
      console.log("Uploaded issue please re-upload");
    }
  };
  const onChangeHandlerPics = async (event) => {
    const imageFile = event.target.files[0];

    console.log("imageFile=====>", imageFile);
    // setSelectedUploadPhoto(imageFile);
    dispatch(setUploadedStatus(false));
    var options = {
      maxSizeMB: 12 / 1024,
      maxWidthOrHeight: 280,
      useWebWorker: true,
    };
    try {
      const compressedFile = await imageCompression(imageFile, options);
      await fileProfileSelected(compressedFile);
      await onHandlerUplaod(compressedFile); // write your own logic
    } catch (error) {
      console.log(error);
    }
  };
  const buttonClickHandler = () => {
    setDidClickButton(true);
  };
  const handleReloadImage = async () => {
    fileInput.current.click();
  };

  useEffect(() => {
    if (selectorUploadStatus) {
      setTimeout(() => {
        setLoading(true);
      }, 2000);
    }
  }, [selectorUploadStatus]);
  useEffect(() => {
    if (didClickButton) {
      fileInput.current.click();
    }
  }, [didClickButton]);
  useEffect(() => {
    if (selectorBannerPicture !== undefined) {
      setSelectedFilename(selectorBannerPicture);
      const splittedFile = selectorBannerPicture.split("_");
      const strFileNameAdditional = splittedFile[2];
      if (splittedFile.length === 4) {
        setFileNameCount(Number(strFileNameAdditional));
      }
      const imgPath = `${imagesRepoUrl}${selectorBannerPicture}`;
      setSelectedProfilePics(imgPath);
    }
  }, [selectorBannerPicture]);
  // useEffect(() => {
  //   const splittedFile = selectedFilename.split("_");
  //   const strFileNameAdditional = splittedFile[2];
  //   if (splittedFile.length === 4) {
  //     setFileNameCount(Number(strFileNameAdditional));
  //   }
  //   const imgPath = `${imagesRepoUrl}uploads/${subscriberRegistrationNo}/profilepics/${selectedFieldID}/${selectedFilename}`;
  //   setSelectedProfilePics(imgPath);
  // }, []);
  const sectionStyle = {
    backgroundImage: `url(${selectedProfilePics})`,
  };
  return (
    <Fragment>
      <div style={{ display: "none" }}>
        <div className="custom-file">
          <input
            name="file"
            type="file"
            // className="custom-file-input"
            id="validatedCustomFile"
            accept="image/*"
            onChange={onChangeHandlerPics}
            ref={fileInput}
          />
        </div>
        <Label className="custom-file-label" htmlFor="validatedCustomFile">
          {"Choose file..."}
        </Label>
        <div className="invalid-feedback">
          {"Example invalid custom file feedback"}
        </div>
      </div>
      <Fragment>
        {loading && (
          <div
            className="employeecard-image-banner"
            onClick={() => handleReloadImage()}
            style={sectionStyle}
          ></div>
        )}
      </Fragment>
    </Fragment>
  );
};
