export class ConfigDB {
	static data = {
		info: {
			name: 'Amjad Khan'
		},
		personalinfo: {
			dob: '12/05/1985',
			address: {
				area: 'Nallgandla',
				city: 'Hyderabad'
			},
			sidebar_setting: 'default-sidebar',
		},
		officialinfo: {
			layout_version: 'light',
			color: 'color-1',
			primary_color: '#7366ff',
			secondary_color: '#f73164',
			mix_background_layout: 'light-only',
		},
		details:[
			{ make: "Toyota", model: "Celica", price: 35000, year: 2003 },
			{ make: "Ford", model: "Mondeo", price: 32000, year: 2021 },
			{ make: "Porsche", model: "Boxter", price: 72000, year: 2022 },
			{ make: "Faizaan", model: "Actor", price: 43000, year: 1987 }
		]
	}
}
export default ConfigDB;




