import React, { useState } from "react";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import { Button } from "reactstrap";

export const ConfirmYesNo = ({
  buttonLable,
  messageTitle,
  messageLabel,
  setYesNo,
}) => {
  const handleYesNo = () => {
    confirmAlert({
      title: messageTitle,
      message: messageLabel,
      buttons: [
        {
          label: "Yes",
          onClick: () => setYesNo(true),
        },
        {
          label: "No",
          onClick: () => setYesNo(false),
        },
      ],
    });
  };
  return (
    <Button color="primary" onClick={handleYesNo}>
      {buttonLable}
    </Button>
  );
};
