import React, { Fragment, useState } from 'react';
import Breadcrumb from '../../../../layout/breadcrumb';
import {useForm} from 'react-hook-form';
import DatePicker from "react-datepicker";
import { AccordionWithOpenandCloseIcon } from "../accordianComponent";
import { Accordion } from "react-bootstrap";
import TooltipForm from '../tooltipForm';
import "react-datepicker/dist/react-datepicker.css";
import {Container,Row,Col,Card,CardHeader,CardBody,Button,Form,FormGroup,Label,Input,InputGroup,InputGroupAddon,InputGroupText} from 'reactstrap';
import {CustomStyles,FirstName,LastName,BrowserDefaults,Tooltips,Username,State,City,Zip,SupportedElements,SubmitForm} from '../../../../constant';

const Sample = () => {
  const { register, handleSubmit, errors } = useForm();
  const [startDate, setstartDate] = useState(new Date())
  
  const handleChange = date => {
    setstartDate(date);
  };

  const onSubmit = data => {
    if (data !== '') {
      alert('You submitted the form and stuff!');
    } else {
      errors.showMessages();
    }
  };
  return (
    <Fragment>
      <Breadcrumb parent="Form" />
      <Container fluid={true}>
        <Row>
          <Col sm="12">
            <Card>
              <CardHeader>
                <h5>{CustomStyles}</h5>
              </CardHeader>
              <CardBody>
                <Form className="needs-validation" noValidate="" onSubmit={handleSubmit(onSubmit)}>
                
                  <div className="form-row">
                    <Col md="4 mb-3">
                  <FormGroup>
                  <Label>Prefix</Label>
                    <Input type="select" className="custom-select" required="">
                      <option value="">{"Select your prefix"}</option>
                      <option value="Mr">{"Mr"}</option>
                      <option value="Mrs">{"Mrs"}</option>
                      <option value="Miss">{"Miss"}</option>
                    </Input>
                    <div className="invalid-feedback">{"Example invalid custom select feedback"}</div>
                  </FormGroup>
                  </Col>
                    <Col md="4 mb-3">
                      <Label htmlFor="validationCustom01">{FirstName}</Label>
                      <Input className="form-control" name="firstName" type="text" placeholder="First name" innerRef={register({ required: true })} />
                      <span>{errors.firstName && 'First name is required'}</span>
                      <div className="valid-feedback">{"Looks good!"}</div>
                    </Col>
                    <Col md="4 mb-3">
                      <Label htmlFor="validationCustom02">{LastName}</Label>
                      <Input className="form-control" name="lastName" type="text" placeholder="Last name" innerRef={register({ required: true })} />
                      <span>{errors.lastName && 'Last name is required'}</span>
                      <div className="valid-feedback">{"Looks good!"}</div>
                    </Col>
                    
                  </div>
               
                  <div className="form-row">
                  <Col md="4 mb-3">
                    <Label htmlFor="validationCustom02">Upload Photo</Label>
                    <div className="custom-file">
                    <Input className="custom-file-input" id="validatedCustomFile" type="file" required="" />
                    <Label className="custom-file-label" htmlFor="validatedCustomFile">{"Choose file..."}</Label>
                    <div className="invalid-feedback">{"Example invalid custom file feedback"}</div>
                  </div>
                  </Col>
                    <Col md="4 mb-3">
                      <Label htmlFor="validationCustom03">Email</Label>
                      <Input className="form-control" name="email" type="email" placeholder="Email" innerRef={register({ required: true })} />
                      <span>{errors.email && 'Please provide a valid email-id'}</span>
                      <div className="invalid-feedback">{"Please provide a valid email-id."}</div>
                    </Col>
                    <Col md="4 mb-3">
                      <Label htmlFor="validationCustom04">Phone Number</Label>
                      <Input className="form-control" id="validationCustom04" name="phonenumber" type="number" placeholder="Phone Number" innerRef={register({ required: true })} />
                      <span>{errors.number && 'Please provide a valid number.'}</span>
                      <div className="invalid-feedback">{"Please provide a valid number."}</div>
                    </Col>
                    </div>
                    <div className="form-row">
                    <Col md="4 mb-3">
                      <Label htmlFor="validationCustom05">Experience(in years)</Label>
                      <Input className="form-control" id="validationCustom05" name="experience" type="number" placeholder="Experience" innerRef={register({ required: true })} />
                      <span >{errors.exp && 'Please provide a valid zip.'}</span>
                      <div className="invalid-feedback">{"Please provide a valid zip."}</div>
                    </Col>
                    <Col md="4 mb-3">
                      <Label>Hire Date</Label>
                      <div className="date-picker">
                      <Form className="theme-form">
                      <FormGroup className="form-row">
                            <div className="input-group">
                              <DatePicker className="form-control digits" selected={startDate} onChange={handleChange} />
                            </div>
                        </FormGroup>
                        </Form>
                        </div>
                    </Col>
                    <Col md="4 mb-3">
                  <FormGroup>
                  <Label>Department</Label>
                    <Input type="select" className="custom-select" required="">
                      <option value=" ">{" "}</option>
                      <option value=" ">{" "}</option>
                      <option value=" ">{" "}</option>
                      <option value=" ">{" "}</option>
                      <option value=" ">{" "}</option>
                      <option value=" ">{" "}</option>
                      <option value=" ">{" "}</option>
                    </Input>
                    <div className="invalid-feedback">{"Example invalid custom select feedback"}</div>
                  </FormGroup>
                  </Col>
                  </div>
                  <div className="form-row">
                  <Col md="4 mb-3">
                  <FormGroup>
                  <Label>Designation</Label>
                    <Input type="select" className="custom-select" required="">
                      <option value=" ">{" "}</option>
                      <option value=" ">{" "}</option>
                      <option value=" ">{" "}</option>
                      <option value=" ">{" "}</option>
                      <option value=" ">{" "}</option>
                      <option value=" ">{" "}</option>
                      <option value=" ">{" "}</option>
                    </Input>  
                    <div className="invalid-feedback">{"Example invalid custom select feedback"}</div>
                  </FormGroup>
                  </Col>
                  <Col md="4 mb-3">
                  <FormGroup>
                  <Label>Gender</Label>
                    <Input type="select" className="custom-select" required="">
                      <option value=" ">{" "}</option>
                      <option value=" ">{" "}</option>
                      <option value=" ">{" "}</option>
                      <option value=" ">{" "}</option>
                    </Input>
                    <div className="invalid-feedback">{"Example invalid custom select feedback"}</div>
                  </FormGroup>
                  </Col>
                  <Col md="4 mb-3">
                  <FormGroup>
                  <Label>Manager</Label>
                    <Input type="select" className="custom-select" required="">
                      <option value="">{""}</option>
                      <option value="">{""}</option>
                      <option value="">{""}</option>
                      <option value="">{""}</option>
                    </Input>
                    <div className="invalid-feedback">{"Example invalid custom select feedback"}</div>
                  </FormGroup>
                  </Col>
                  </div>
                  <div className="form-row">
                    <Col md="6 mb-3">
                    <FormGroup className="mb-0">
                        <Label>{"Memberships"}</Label>
                        <Input type="textarea" className="form-control"  rows="2"/>
                      </FormGroup>
                    </Col>
                    <Col md="6 mb-3">
                    <FormGroup className="mb-0">
                        <Label>{"Key Responsibilties"}</Label>
                        <Input type="textarea" className="form-control"  rows="2"/>
                      </FormGroup>
                    </Col>
                  </div>
          <Col md="12 mb-3">
            <Accordion defaultActiveKey="0">
              <div className="default-according style-1" id="accordionoc">
                    <AccordionWithOpenandCloseIcon />
                  </div>
            </Accordion>
          </Col>
          <Button color="primary" ><center>{"Submit form"}</center></Button>
        </Form>
      </CardBody>
     </Card>
    </Col>
  </Row>
</Container>
</Fragment>
  );
};
export default Sample;