import React, { Fragment, useState, useEffect, useRef } from "react";
import { useHistory } from "react-router-dom";
import Breadcrumb from "../../../../layout/breadcrumb";
import { useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import DatePicker from "react-datepicker";
import { AccordionWithOpenandCloseIcon } from "../accordianComponent";
import { Accordion } from "react-bootstrap";
import MultiSelect from "react-multi-select-component";
import TooltipForm from "../tooltipForm";
import "react-datepicker/dist/react-datepicker.css";
import {
  Container,
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  Button,
  Form,
  FormGroup,
  Label,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
} from "reactstrap";
import {
  EmployeeTitle,
  FirstName,
  LastName,
  EmployeeBrowserDefaults,
  ooltips,
  Username,
  State,
  City,
  Zip,
  SupportedElements,
  EmployeeSubmitForm,
} from "../../../../constant";
import {
  getEmployees,
  addEmployeeDetails,
} from "../../../../services/subscriberService";
import { getAllGenders } from "../../../../state/selectors/genders";
import { getAllMemberships } from "../../../../state/selectors/memberships";
import { getAllDesignations } from "../../../../state/selectors/designations";
import { getAllPrefix } from "../../../../state/selectors/prefix";
import { getAllCategories } from "../../../../state/selectors/categories";
import {
  subscriberRegistrationNo,
  profileImageFormat,
} from "../../../../config.json";
import { UploadSingleFile } from "../../../common/UploadSingleFile";

const EmployeeAdd = () => {
  const { register, handleSubmit, errors } = useForm();
  const [progressValue, setProgressValue] = useState();
  const [contentKeyResponsibilties, setContentKeyResponsibilties] = useState(
    ""
  );
  const [contentBiography, setContentBiography] = useState("");
  const [
    contentResearchAndPublications,
    setContentResearchAndPublications,
  ] = useState("");
  const [contentConference, setContentConference] = useState("");
  const [contentAboutMe, setContentAboutMe] = useState("");
  const [contentPaperPresentation, setContentPaperPresentation] = useState("");
  const [selectedStartDate, setSelectedStartDate] = useState(new Date());
  const [savedSuccess, setSavedSuccess] = useState(false);
  const [selectedCategoryI, setSelectedCategoryI] = useState(0);
  const [selectedCategoryII, setSelectedCategoryII] = useState(0);
  const [selectedCategoryIII, setSelectedCategoryIII] = useState(0);
  const [selectedPrefix, setSelectedPrefix] = useState(0);
  const [selectedMemberships, setSelectedMemberships] = useState(0);
  const [selectedDesignation, setSelectedDesignation] = useState(0);
  const [selectedEmployeeID, setSelectedEmployeeID] = useState("");
  const [selectedJobID, setSelectedJobID] = useState(0);
  const [selectedGender, setSelectedGender] = useState(0);
  const [selectedManager, setSelectedManager] = useState(0);
  const [selectedFirstName, setSelectedFirstName] = useState("");
  const [selectedLastName, setSelectedLastName] = useState("");
  const [selectedPhoneNumber, setSelectedPhoneNumber] = useState("");
  const [selectedEmail, setSelectedEmail] = useState("");
  const [
    selectedHighestQualification,
    setSelectedHighestQualification,
  ] = useState("");
  const [selectedOtherQualification, setSelectedOtherQualification] = useState(
    ""
  );
  const [selectedExperienceInYears, setSelectedExperienceInYears] = useState(
    ""
  );
  const [dataCategoryI, setDataCategoryI] = useState([]);
  const [dataCategoryII, setDataCategoryII] = useState([]);
  const [dataCategoryIII, setDataCategoryIII] = useState([]);
  const [dataSelectedEmployees, setDataSelectedEmployees] = useState([]);
  const [name, setName] = useState([]);
  const [optionsProducts, setOptionsProducts] = useState([
    { label: "", value: "" },
  ]);
  const allGenders = useSelector(getAllGenders);
  const allMemberships = useSelector(getAllMemberships);
  const allDesignations = useSelector(getAllDesignations);
  const allPrefix = useSelector(getAllPrefix);
  const allCategories = useSelector(getAllCategories);
  const history = useHistory();
  const handleChangeStartDate = (date) => {
    setSelectedStartDate(date);
  };
  const onSubmit = (data) => {
    if (data !== "") {
      alert("You submitted the form and stuff!");
    } else {
      errors.showMessages();
    }
  };
  const handleChangeCategoryI = (e) => {
    const filteredCategories =
      allCategories &&
      allCategories.filter((item) => item.ParentId == e.target.value);
    setDataCategoryII(filteredCategories);
    setSelectedCategoryI(e.target.value);
    setSelectedCategoryII(0);
    setSelectedCategoryIII(0);
  };
  const handleChangeCategoryII = (e) => {
    const filteredCategories =
      allCategories &&
      allCategories.filter((item) => item.ParentId == e.target.value);
    setDataCategoryIII(filteredCategories);
    setSelectedCategoryII(e.target.value);
    setSelectedCategoryIII(0);
  };

  const setEmployeeDetails = async (catId) => {
    const employeesResult = await getEmployees(
      catId,
      subscriberRegistrationNo,
      ""
    );
    setDataSelectedEmployees(employeesResult.data[0]);
  };
  const handleChangeCategoryIII = (e) => {
    setEmployeeDetails(e.target.value);
    setSelectedCategoryIII(e.target.value);

    console.log(
      "e.target.value==>",
      e.target.value,
      "--",
      selectedCategoryI,
      "---",
      selectedCategoryII,
      ">>"
    );
  };
  const handleChangeManager = (e) => {
    setSelectedManager(e.target.value);
  };
  const handleChangePrefix = (e) => {
    setSelectedPrefix(e.target.value);
  };
  const saveEmployeeData = async (buildRecords) => {
    const result = await addEmployeeDetails(
      buildRecords,
      `${subscriberRegistrationNo}_${selectedEmployeeID}${profileImageFormat}`
    );
    if (result.status === 200) {
      setSavedSuccess(true);
      history.push("/");
    }
  };
  const formatMailProductsList = (selectedProducts) => {
    let prodList = [];
    selectedProducts.forEach((element) => {
      prodList.push(element.label);
    });
    return prodList.join().replace(/,/g, ", ");
  };
  const handleSubmitData = (e) => {
    const prodToList = formatMailProductsList(name);
    const records = [
      {
        parentregistrationno: subscriberRegistrationNo,
        contentKeyResponsibilties,
        contentBiography,
        contentResearchAndPublications,
        contentConference,
        contentAboutMe,
        contentRemark1: "",
        contentRemark2: "",
        contentRemark2: "",
        contentPaperPresentation,
        selectedStartDate,
        selectedCategoryI,
        selectedCategoryII,
        selectedCategoryIII,
        selectedPrefix,
        selectedMemberships: prodToList,
        selectedDesignation,
        selectedGender,
        selectedManager,
        selectedFirstName,
        selectedLastName,
        selectedUploadPhoto: `${subscriberRegistrationNo}_${selectedEmployeeID}${profileImageFormat}`,
        selectedPhoneNumber,
        selectedEmail,
        selectedHighestQualification,
        selectedOtherQualification,
        selectedEmployeeID,
        selectedJobID,
        selectedExperienceInYears,
        modifiedby: "Admin",
        istransaction: "true",
      },
    ];
    if (selectedFirstName && selectedLastName) {
      const buildRecords = JSON.stringify(records).replace(/"/g, '"');
      saveEmployeeData(buildRecords);
    }
    e.preventDefault();
  };
  const handleChangeMemberships = (e) => {
    setSelectedMemberships(e.target.value);
  };
  const handleChangeDesignation = (e) => {
    setSelectedDesignation(e.target.value);
  };
  const handleChangeGender = (e) => {
    setSelectedGender(e.target.value);
  };
  const handleChangeFirstName = (e) => {
    setSelectedFirstName(e.target.value);
  };
  const handleChangeLastName = (e) => {
    setSelectedLastName(e.target.value);
  };
  // const handleChangeUploadPhoto = e =>{
  //   console.log("e.target.files[0]=======>",e.target.files[0].name)
  //   setSelectedUploadPhoto(e.target.files[0]);
  // }
  const handleChangePhoneNumber = (e) => {
    setSelectedPhoneNumber(e.target.value);
  };
  const handleChangeEmail = (e) => {
    setSelectedEmail(e.target.value);
  };
  const handleChangeHighestQualification = (e) => {
    setSelectedHighestQualification(e.target.value);
  };
  const handleChangeOtherQualification = (e) => {
    setSelectedOtherQualification(e.target.value);
  };
  const handleChangeJobID = (e) => {
    setSelectedJobID(e.target.value);
  };
  const handleChangeExperienceInYears = (e) => {
    setSelectedExperienceInYears(e.target.value);
  };
  const handleContentBiography = (evt) => {
    const newContent = evt.editor.getData();
    setContentBiography(newContent);
  };
  const handleContentConference = (evt) => {
    const newContent = evt.editor.getData();
    setContentConference(newContent);
  };
  const handleContentAboutMe = (evt) => {
    const newContent = evt.editor.getData();
    setContentAboutMe(newContent);
  };
  const handleContentKeyResponsibilties = (evt) => {
    const newContent = evt.editor.getData();
    setContentKeyResponsibilties(newContent);
  };
  const handleContentPaperPresentation = (evt) => {
    const newContent = evt.editor.getData();
    setContentPaperPresentation(newContent);
  };
  const handleContentResearchAndPublications = (evt) => {
    const newContent = evt.editor.getData();
    setContentResearchAndPublications(newContent);
  };

  useEffect(() => {
    const filteredCategories =
      allCategories &&
      allCategories.filter(
        (item) => item.ParentId === null || item.ParentId === 0
      );
    setDataCategoryI(filteredCategories);
  }, [allCategories]);

  useEffect(() => {
    const arrData = [];
    allMemberships &&
      allMemberships.forEach((element) => {
        element["label"] = element["name"].replace(/&amp;/g, "&");
        element["value"] = element["id"];
        arrData.push(element);
      });
    setOptionsProducts(arrData);
  }, [allMemberships]);
  return (
    <Fragment>
      <Breadcrumb parent="Form" />
      {savedSuccess ? (
        <p>Employee has been added successfully!</p>
      ) : (
        <Container fluid={true}>
          <Row>
            <Col sm="12">
              <Card>
                <CardHeader>
                  <h5>{EmployeeTitle}</h5>
                </CardHeader>
                <CardBody>
                  <Form
                    className="needs-validation"
                    noValidate=""
                    onSubmit={handleSubmitData}
                  >
                    <div className="form-row">
                      <Col md="4 mb-3">
                        <FormGroup>
                          <Label>Category I</Label>
                          <Input
                            type="select"
                            className="custom-select"
                            required=""
                            value={selectedCategoryI}
                            onChange={handleChangeCategoryI}
                          >
                            <option value="0">{"Select..."}</option>
                            {dataCategoryI &&
                              dataCategoryI.length > 0 &&
                              dataCategoryI.map((category) => {
                                return (
                                  <option value={category.CategoryId}>
                                    {category.CategoryName}
                                  </option>
                                );
                              })}
                          </Input>
                          <div className="invalid-feedback">
                            {"Example invalid custom select feedback"}
                          </div>
                        </FormGroup>
                      </Col>
                      <Col md="4 mb-3">
                        <FormGroup>
                          <Label>Category II</Label>
                          <Input
                            type="select"
                            className="custom-select"
                            required=""
                            value={selectedCategoryII}
                            onChange={handleChangeCategoryII}
                          >
                            <option value="0">{"Select..."}</option>
                            {dataCategoryII &&
                              dataCategoryII.length > 0 &&
                              dataCategoryII.map((category) => {
                                return (
                                  <option value={category.CategoryId}>
                                    {category.CategoryName}
                                  </option>
                                );
                              })}
                          </Input>
                          <div className="invalid-feedback">
                            {"Example invalid custom select feedback"}
                          </div>
                        </FormGroup>
                      </Col>
                      <Col md="4 mb-3">
                        <FormGroup>
                          <Label>Category III</Label>
                          <Input
                            type="select"
                            className="custom-select"
                            required=""
                            value={selectedCategoryIII}
                            onChange={handleChangeCategoryIII}
                          >
                            <option value="0">{"Select..."}</option>
                            {dataCategoryIII &&
                              dataCategoryIII.length > 0 &&
                              dataCategoryIII.map((category) => {
                                return (
                                  <option value={category.CategoryId}>
                                    {category.CategoryName}
                                  </option>
                                );
                              })}
                          </Input>
                          <div className="invalid-feedback">
                            {"Example invalid custom select feedback"}
                          </div>
                        </FormGroup>
                      </Col>
                    </div>
                    <div className="form-row">
                      <Col md="4 mb-3">
                        <FormGroup>
                          <Label>Prefix</Label>
                          <Input
                            type="select"
                            className="custom-select"
                            required=""
                            value={selectedPrefix}
                            onChange={handleChangePrefix}
                          >
                            <option value="0">{"Select..."}</option>
                            {allPrefix &&
                              allPrefix.length > 0 &&
                              allPrefix.map((category) => {
                                return (
                                  <option value={category.id}>
                                    {category.name}
                                  </option>
                                );
                              })}
                          </Input>
                          <div className="invalid-feedback">
                            {"Example invalid custom select feedback"}
                          </div>
                        </FormGroup>
                      </Col>
                      <Col md="4 mb-3">
                        <Label htmlFor="validationCustom01">{FirstName}</Label>
                        <Input
                          className="form-control"
                          name="firstName"
                          type="text"
                          placeholder="First name"
                          innerRef={register({ required: true })}
                          onBlur={handleChangeFirstName}
                        />
                        <span>
                          {errors.firstName && "First name is required"}
                        </span>
                        <div className="valid-feedback">{"Looks good!"}</div>
                      </Col>
                      <Col md="4 mb-3">
                        <Label htmlFor="validationCustom02">{LastName}</Label>
                        <Input
                          className="form-control"
                          name="lastName"
                          type="text"
                          placeholder="Last name"
                          innerRef={register({ required: true })}
                          onBlur={handleChangeLastName}
                        />
                        <span>
                          {errors.lastName && "Last name is required"}
                        </span>
                        <div className="valid-feedback">{"Looks good!"}</div>
                      </Col>
                    </div>

                    <div className="form-row">
                      <Col md="4 mb-3">
                        <UploadSingleFile
                          setSelectedEmployeeID={setSelectedEmployeeID}
                          setProgressValue={setProgressValue}
                        />
                      </Col>
                      <Col md="4 mb-3">
                        <Label htmlFor="validationCustom03">Email</Label>
                        <Input
                          className="form-control"
                          name="email"
                          type="email"
                          placeholder="Email"
                          innerRef={register({ required: true })}
                          onBlur={handleChangeEmail}
                        />
                        <span>
                          {errors.email && "Please provide a valid email-id"}
                        </span>
                        <div className="invalid-feedback">
                          {"Please provide a valid email-id."}
                        </div>
                      </Col>
                      <Col md="4 mb-3">
                        <Label htmlFor="validationCustom04">Phone Number</Label>
                        <Input
                          className="form-control"
                          id="validationCustom04"
                          name="phonenumber"
                          type="number"
                          placeholder="Phone Number"
                          innerRef={register({ required: true })}
                          onBlur={handleChangePhoneNumber}
                        />
                        <span>
                          {errors.number && "Please provide a valid number."}
                        </span>
                        <div className="invalid-feedback">
                          {"Please provide a valid number."}
                        </div>
                      </Col>
                    </div>
                    <div className="form-row">
                      <Col md="4 mb-3">
                        <Label htmlFor="validationCustom05">Job ID</Label>
                        <Input
                          className="form-control"
                          id="validationCustom05"
                          name="jobid"
                          type="number"
                          placeholder="Job ID"
                          innerRef={register({ required: true })}
                          onBlur={handleChangeJobID}
                        />
                        <span>
                          {errors.exp && "Please provide a valid zip."}
                        </span>
                        <div className="invalid-feedback">
                          {"Please provide a valid zip."}
                        </div>
                      </Col>

                      <Col md="4 mb-3">
                        <Label htmlFor="validationCustom05">
                          Highest Qualification
                        </Label>
                        <Input
                          className="form-control"
                          id="validationCustom05"
                          name="highestqualification"
                          type="text"
                          placeholder="Highest Qualification"
                          innerRef={register({ required: true })}
                          onBlur={handleChangeHighestQualification}
                        />
                        <span>
                          {errors.exp && "Please provide a valid zip."}
                        </span>
                        <div className="invalid-feedback">
                          {"Please provide a valid zip."}
                        </div>
                      </Col>
                      <Col md="4 mb-3">
                        <Label htmlFor="validationCustom05">
                          Other Qualification
                        </Label>
                        <Input
                          className="form-control"
                          id="validationCustom05"
                          name="otherqualification"
                          type="text"
                          placeholder="Other Qualification"
                          innerRef={register({ required: true })}
                          onBlur={handleChangeOtherQualification}
                        />
                        <span>
                          {errors.exp && "Please provide a valid zip."}
                        </span>
                        <div className="invalid-feedback">
                          {"Please provide a valid zip."}
                        </div>
                      </Col>
                    </div>

                    <div className="form-row">
                      <Col md="4 mb-3">
                        <Label htmlFor="validationCustom05">
                          Experience(in years)
                        </Label>
                        <Input
                          className="form-control"
                          id="validationCustom05"
                          name="experience"
                          type="number"
                          placeholder="Experience"
                          innerRef={register({ required: true })}
                          onBlur={handleChangeExperienceInYears}
                        />
                        <span>
                          {errors.exp && "Please provide a valid zip."}
                        </span>
                        <div className="invalid-feedback">
                          {"Please provide a valid zip."}
                        </div>
                      </Col>
                      <Col md="4 mb-3">
                        <Label>Hire Date</Label>
                        <div className="date-picker">
                          <Form className="theme-form">
                            <FormGroup className="form-row">
                              <div className="input-group">
                                <DatePicker
                                  className="form-control digits"
                                  selected={selectedStartDate}
                                  onChange={handleChangeStartDate}
                                />
                              </div>
                            </FormGroup>
                          </Form>
                        </div>
                      </Col>
                      <Col md="4 mb-3">
                        <FormGroup>
                          <Label>Memberships</Label>
                          {optionsProducts && optionsProducts.length > 0 && (
                            <MultiSelect
                              options={optionsProducts}
                              value={name}
                              onChange={setName}
                              labelledBy={"Select Product"}
                              disableSearch={true}
                              overrideStrings={{
                                selectSomeItems: "Select Products",
                                allItemsAreSelected:
                                  "All Products are selected",
                                selectAll: "Select All Products",
                                search: "Search",
                                clearSearch: "Clear Search",
                              }}
                            />
                          )}
                          <div className="invalid-feedback">
                            {"Example invalid custom select feedback"}
                          </div>
                        </FormGroup>
                      </Col>
                      <Col md="4 mb-3">
                        <FormGroup>
                          <Label>Designation</Label>
                          <Input
                            type="select"
                            className="custom-select"
                            required=""
                            value={selectedDesignation}
                            onChange={handleChangeDesignation}
                          >
                            <option value="0">{"Select..."}</option>
                            {allDesignations &&
                              allDesignations.length > 0 &&
                              allDesignations.map((design) => {
                                return (
                                  <option value={design.id}>
                                    {design.name}
                                  </option>
                                );
                              })}
                          </Input>
                          <div className="invalid-feedback">
                            {"Example invalid custom select feedback"}
                          </div>
                        </FormGroup>
                      </Col>
                      <Col md="4 mb-3">
                        <FormGroup>
                          <Label>Gender</Label>
                          <Input
                            type="select"
                            className="custom-select"
                            required=""
                            value={selectedGender}
                            onChange={handleChangeGender}
                          >
                            <option value="0">{"Select..."}</option>
                            {allGenders &&
                              allGenders.length > 0 &&
                              allGenders.map((gender) => {
                                return (
                                  <option value={gender.id}>
                                    {gender.name}
                                  </option>
                                );
                              })}
                          </Input>
                          <div className="invalid-feedback">
                            {"Example invalid custom select feedback"}
                          </div>
                        </FormGroup>
                      </Col>
                      <Col md="4 mb-3">
                        <FormGroup>
                          <Label>Manager</Label>
                          <Input
                            type="select"
                            className="custom-select"
                            required=""
                            value={selectedManager}
                            onChange={handleChangeManager}
                          >
                            <option value="0">{"Select..."}</option>
                            {dataSelectedEmployees &&
                              dataSelectedEmployees.length > 0 &&
                              dataSelectedEmployees.map((emp) => {
                                return (
                                  <option
                                    value={emp.EmpID}
                                  >{`${emp.prefix} ${emp.FirstName} ${emp.LastName}`}</option>
                                );
                              })}
                          </Input>
                          <div className="invalid-feedback">
                            {"Example invalid custom select feedback"}
                          </div>
                        </FormGroup>
                      </Col>
                    </div>
                    <Col md="12 mb-3">
                      <Accordion defaultActiveKey="0">
                        <div
                          className="default-according style-1"
                          id="accordionoc"
                        >
                          <AccordionWithOpenandCloseIcon
                            setContentKeyResponsibilties={
                              handleContentKeyResponsibilties
                            }
                            setContentBiography={handleContentBiography}
                            setContentResearchAndPublications={
                              handleContentResearchAndPublications
                            }
                            setContentConference={handleContentConference}
                            setContentPaperPresentation={
                              handleContentPaperPresentation
                            }
                            setContentAboutMe={handleContentAboutMe}
                            contentKeyResponsibilties={
                              contentKeyResponsibilties
                            }
                            contentBiography={contentBiography}
                            contentResearchAndPublications={
                              contentResearchAndPublications
                            }
                            contentConference={contentConference}
                            contentPaperPresentation={contentPaperPresentation}
                            contentAboutMe={contentAboutMe}
                          />
                        </div>
                      </Accordion>
                    </Col>
                    <Button color="primary">
                      <center>{"Submit form"}</center>
                    </Button>
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      )}
    </Fragment>
  );
};
export default EmployeeAdd;
