import React, { Fragment, useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import Breadcrumb from "../../../layout/breadcrumb";
import { useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import {
  Container,
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  Button,
  Form,
  FormGroup,
  Label,
  Input,
} from "reactstrap";
import Dropzones from "./dropzone";
import {
  getEmployees,
  addEmployeeDetails,
} from "../../../services/subscriberService";
import { getAllCategories } from "../../../state/selectors/categories";
import { subscriberRegistrationNo } from "../../../config.json";

const Gallery = (props) => {
  const { register, handleSubmit, errors } = useForm();
  const [savedSuccess, setSavedSuccess] = useState(false);
  const [selectedCategoryI, setSelectedCategoryI] = useState(0);
  const [selectedCategoryII, setSelectedCategoryII] = useState(0);
  const [selectedCategoryIII, setSelectedCategoryIII] = useState(0);
  const [selectedFileType, setSelectedFileType] = useState(0);
  const [selectedUploadPhoto, setSelectedUploadPhoto] = useState("");
  const [dataCategoryI, setDataCategoryI] = useState([]);
  const [dataCategoryII, setDataCategoryII] = useState([]);
  const [dataCategoryIII, setDataCategoryIII] = useState([]);
  const [dataSelectedEmployees, setDataSelectedEmployees] = useState([]);
  const allCategories = useSelector(getAllCategories);
  const history = useHistory();

  const onSubmit = (data) => {
    if (data !== "") {
      alert("You submitted the form and stuff!");
    } else {
      errors.showMessages();
    }
  };
  const handleChangeCategoryI = (e) => {
    const filteredCategories =
      allCategories &&
      allCategories.filter((item) => item.ParentId == e.target.value);
    setDataCategoryII(filteredCategories);
    setSelectedCategoryI(e.target.value);
    setSelectedCategoryII(0);
    setSelectedCategoryIII(0);
  };
  const handleChangeCategoryII = (e) => {
    const filteredCategories =
      allCategories &&
      allCategories.filter((item) => item.ParentId == e.target.value);
    setDataCategoryIII(filteredCategories);
    setSelectedCategoryII(e.target.value);
    setSelectedCategoryIII(0);
  };
  const setEmployeeDetails = async (catId) => {
    const employeesResult = await getEmployees(
      catId,
      subscriberRegistrationNo,
      ""
    );
    console.log("employeesResult[0]====>", employeesResult.data[0]);
    setDataSelectedEmployees(employeesResult.data[0]);
  };
  const handleChangeCategoryIII = (e) => {
    setEmployeeDetails(e.target.value);
    setSelectedCategoryIII(e.target.value);

    console.log(
      "e.target.value==>",
      e.target.value,
      "--",
      selectedCategoryI,
      "---",
      selectedCategoryII,
      ">>"
    );
  };
  const handleSelectedFileType = (e) => {
    setSelectedFileType(e.target.value);
  };
  const saveEmployeeData = async (buildRecords) => {
    const result = await addEmployeeDetails(buildRecords);
    if (result.status === 200) {
      setSavedSuccess(true);
      history.push("/");
    }
  };
  const handleSubmitData = (e) => {
    const records = [
      {
        parentregistrationno: subscriberRegistrationNo,
        selectedCategoryI,
        selectedCategoryII,
        selectedCategoryIII,
        selectedUploadPhoto: "pic_2.jpg",
        modifiedby: "Admin",
        istransaction: "true",
      },
    ];
    const buildRecords = JSON.stringify(records).replace(/"/g, '"');
    saveEmployeeData(buildRecords);
    e.preventDefault();
  };
  const handleChangeUploadPhoto = (e) => {
    setSelectedUploadPhoto("pic_2.jpg");
  };

  useEffect(() => {
    const filteredCategories =
      allCategories && allCategories.filter((item) => item.ParentId === null);
    setDataCategoryI(filteredCategories);
  }, [allCategories]);
  return (
    <Fragment>
      <Breadcrumb parent="Gallery" />
      {savedSuccess ? (
        <p>Employee has been added successfully!</p>
      ) : (
        <Container fluid={true}>
          <Row>
            <Col sm="12">
              <Card>
                <CardHeader>
                  <h5>{"Add Photos PPT PDF Documents Excels"}</h5>
                </CardHeader>
                <CardBody>
                  <Form
                    className="needs-validation"
                    noValidate=""
                    onSubmit={handleSubmitData}
                  >
                    <div className="form-row">
                      <Col md="3 mb-3">
                        <FormGroup>
                          <Label>Category I</Label>
                          <Input
                            type="select"
                            className="custom-select"
                            required=""
                            value={selectedCategoryI}
                            onChange={handleChangeCategoryI}
                          >
                            <option value="0">{"Select..."}</option>
                            {dataCategoryI &&
                              dataCategoryI.length > 0 &&
                              dataCategoryI.map((category) => {
                                return (
                                  <option value={category.CategoryId}>
                                    {category.CategoryName}
                                  </option>
                                );
                              })}
                          </Input>
                          <div className="invalid-feedback">
                            {"Example invalid custom select feedback"}
                          </div>
                        </FormGroup>
                      </Col>
                      <Col md="3 mb-3">
                        <FormGroup>
                          <Label>Category II</Label>
                          <Input
                            type="select"
                            className="custom-select"
                            required=""
                            value={selectedCategoryII}
                            onChange={handleChangeCategoryII}
                          >
                            <option value="0">{"Select..."}</option>
                            {dataCategoryII &&
                              dataCategoryII.length > 0 &&
                              dataCategoryII.map((category) => {
                                return (
                                  <option value={category.CategoryId}>
                                    {category.CategoryName}
                                  </option>
                                );
                              })}
                          </Input>
                          <div className="invalid-feedback">
                            {"Example invalid custom select feedback"}
                          </div>
                        </FormGroup>
                      </Col>
                      <Col md="3 mb-3">
                        <FormGroup>
                          <Label>Category III</Label>
                          <Input
                            type="select"
                            className="custom-select"
                            required=""
                            value={selectedCategoryIII}
                            onChange={handleChangeCategoryIII}
                          >
                            <option value="0">{"Select..."}</option>
                            {dataCategoryIII &&
                              dataCategoryIII.length > 0 &&
                              dataCategoryIII.map((category) => {
                                return (
                                  <option value={category.CategoryId}>
                                    {category.CategoryName}
                                  </option>
                                );
                              })}
                          </Input>
                          <div className="invalid-feedback">
                            {"Example invalid custom select feedback"}
                          </div>
                        </FormGroup>
                      </Col>
                      <Col md="3 mb-3">
                        <FormGroup>
                          <Label>File Type</Label>
                          <Input
                            type="select"
                            className="custom-select"
                            required=""
                            value={selectedFileType}
                            onChange={handleSelectedFileType}
                          >
                            <option value="0">{"Select..."}</option>
                            <option value="IMG">{"Images"}</option>
                            <option value="PDF">{"PDFs"}</option>
                            <option value="DOC">{"Words"}</option>
                            <option value="PPT">{"Presentations"}</option>
                            <option value="XLS">{"Excel Sheets"}</option>
                            <option value="DWG">{"Drawings dwg"}</option>
                          </Input>
                          <div className="invalid-feedback">
                            {"Example invalid custom select feedback"}
                          </div>
                        </FormGroup>
                      </Col>
                    </div>
                    <div className="form-row">
                      <Col lg={12}>
                        <Dropzones
                          categoryI={selectedCategoryI}
                          categoryII={selectedCategoryII}
                          categoryIII={selectedCategoryIII}
                          fileType={selectedFileType}
                        />
                      </Col>
                    </div>
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      )}
    </Fragment>
  );
};
export default Gallery;
