import React, { Fragment, useEffect } from "react";
import { useDispatch } from "react-redux";
import Loader from "../layout/loader";
import {
  getMultipleLevelCategory,
  getEmployees,
  getMembership,
  getDesignations,
  getPrefix,
  getAffairstype,
} from "../services/subscriberService";
import { getGenderMaster } from "../services/genderMasterService";
import { setCategories } from "../state/actions/categories";
import { setGenders } from "../state/actions/genders";
import { setEmployees } from "../state/actions/employees";
import { setMemberships } from "../state/actions/memberships";
import { setDesignations } from "../state/actions/designations";
import { subscriberRegistrationNo } from "../config.json";
import { setPrefix } from "../state/actions/prefix";
import { setAffairstype } from "../state/actions/affairs";

const Startup = () => {
  const dispatch = useDispatch();
  const initialDataSetup = async () => {
    const result = await getMultipleLevelCategory(subscriberRegistrationNo);
    dispatch(setCategories(result.data[0]));
    const gendersResult = await getGenderMaster();
    dispatch(setGenders(gendersResult.data[0]));
    const employeesResult = await getEmployees(
      "18",
      subscriberRegistrationNo,
      ""
    );
    dispatch(setEmployees(employeesResult.data[0]));
    const membershipsResults = await getMembership(subscriberRegistrationNo);
    dispatch(setMemberships(membershipsResults.data[0]));
    const designationsResults = await getDesignations(subscriberRegistrationNo);
    dispatch(setDesignations(designationsResults.data[0]));
    const prefixResults = await getPrefix(subscriberRegistrationNo);
    dispatch(setPrefix(prefixResults.data[0]));
    const affairstypeResults = await getAffairstype(subscriberRegistrationNo);
    dispatch(setAffairstype(affairstypeResults.data[0]));
  };
  useEffect(() => {
    initialDataSetup();
  }, []);
  console.warn = () => {};
  return (
    <Fragment>
      <Loader />
    </Fragment>
  );
};

export default Startup;
