import { 
        SET_DESIGNATIONS
    } from "../actionTypes";

const initial_state = {
    designations: []
};

export default (state = initial_state, action) => {
    switch (action.type) {
        case SET_DESIGNATIONS:
            return { ...state, loading: false, designations: action.data };

        default: return { ...state };
    }
}
