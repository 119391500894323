import React, { Fragment, useState, useEffect, useRef } from "react";
import { useHistory } from "react-router-dom";
import Breadcrumb from "../../../../layout/breadcrumb";
import SearchIcon from "@material-ui/icons/Search";
import { useSelector, useDispatch } from "react-redux";
import { useClearCache } from "react-clear-cache";
import { useForm } from "react-hook-form";
import { uuid } from "uuidv4";
import { CommonDataGrid } from "./CommonDataGrid";
import { getComponentElement } from "../ControllerComps";
import { setSelctedEmployee } from "../../../../state/actions/employees";
import "react-datepicker/dist/react-datepicker.css";
import {
  Container,
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  Button,
  Form,
  FormGroup,
  Label,
  Input,
} from "reactstrap";
import { EmployeeUpdateTitle } from "../../../../constant";
import {
  getEmployees,
  addHomeLevelDetails,
} from "../../../../services/subscriberService";
import { getAllCategories } from "../../../../state/selectors/categories";
import { getSelectedEmployee } from "../../../../state/selectors/employees";
import {
  subscriberRegistrationNo,
  profileImageFormat,
} from "../../../../config.json";
import "./SearchBoxByCategory.scss";

const CommonSearch = () => {
  const { isLatestVersion, emptyCacheStorage } = useClearCache();
  const { register, handleSubmit, errors } = useForm();
  const selectedEmployeeData = useSelector(getSelectedEmployee);
  const dispatch = useDispatch();
  const [savedSuccess, setSavedSuccess] = useState(false);
  const [selectedSearchText, setSelectedSearchText] = useState("");
  const [selectedCategoryI, setSelectedCategoryI] = useState(0);
  const [selectedCategoryII, setSelectedCategoryII] = useState(0);
  const [selectedCategoryIII, setSelectedCategoryIII] = useState(0);

  const [dataCategoryI, setDataCategoryI] = useState([]);
  const [dataCategoryII, setDataCategoryII] = useState([]);
  const [dataCategoryIII, setDataCategoryIII] = useState([]);
  const [dataSelectedEmployees, setDataSelectedEmployees] = useState([]);
  const [columnsEmployees] = useState([
    "JobID",
    "prefix",
    "FirstName",
    "LastName",
    "genderName",
    "designame",
    "Email",
    "DeptName",
    "PhoneNumber",
  ]);
  const [name, setName] = useState([]);
  const [showUpdate, setShowUpdate] = useState(false);
  const allCategories = useSelector(getAllCategories);
  const history = useHistory();
  const onSubmit = (data) => {
    console.log("data submit====>", data);
    if (data !== "") {
      alert("You submitted the form and stuff!");
    } else {
      errors.showMessages();
    }
  };
  const handleSearchText = (e) => {
    setSelectedSearchText(e.target.value);
  };
  const handleChangeCategoryI = (e) => {
    const filteredCategories =
      allCategories &&
      allCategories.filter((item) => item.ParentId == e.target.value);
    setDataCategoryII(filteredCategories);
    setSelectedCategoryI(e.target.value);
    setSelectedCategoryII(0);
    setSelectedCategoryIII(0);
  };
  const handleChangeCategoryII = (e) => {
    const filteredCategories =
      allCategories &&
      allCategories.filter((item) => item.ParentId == e.target.value);
    setDataCategoryIII(filteredCategories);
    setSelectedCategoryII(e.target.value);
    setSelectedCategoryIII(0);
  };

  const setEmployeeDetails = async () => {
    const employeesResult = await getEmployees(
      selectedCategoryIII,
      subscriberRegistrationNo,
      selectedSearchText
    );
    setDataSelectedEmployees(employeesResult.data[0]);
  };
  const refreshCacheAndReload = () => {
    if (caches) {
      // Service worker cache should be cleared with caches.delete()
      console.log("caches===>", caches);
      caches.keys().then((names) => {
        for (const name of names) {
          caches.delete(name);
        }
      });
    }
    // delete browser cache and hard reload
    window.location.reload(true);
  };

  const handleChangeCategoryIII = (e) => {
    setSelectedCategoryIII(e.target.value);
  };

  const formatMailProductsList = (selectedProducts) => {
    let prodList = [];
    selectedProducts.forEach((element) => {
      prodList.push(element.label);
    });
    return prodList.join().replace(/,/g, ", ");
  };
  const handleSubmitData = (e) => {
    const prodToList = formatMailProductsList(name);
    const records = [
      {
        id: uuid(),
        registrationno: subscriberRegistrationNo,
        categorylevel1: selectedCategoryI,
        categorylevel2: selectedCategoryII,
        categorylevel3: selectedCategoryIII,
      },
    ];
    e.preventDefault();
  };
  const handleSelectionChanged = (selectedRows) => {
    dispatch(setSelctedEmployee(selectedRows[0]));
    setShowUpdate(true);
  };
  useEffect(() => {
    setEmployeeDetails();
  }, [selectedCategoryIII, selectedSearchText]);
  // useEffect(() => {
  //   selectedEmployeeData && setShowUpdate(true);
  // }, [selectedEmployeeData]);
  useEffect(() => {
    const filteredCategories =
      allCategories &&
      allCategories.filter(
        (item) => item.ParentId === null || item.ParentId === 0
      );
    setDataCategoryI(filteredCategories);
  }, [allCategories]);
  return (
    <Fragment>
      <Breadcrumb parent="Form" />
      {savedSuccess ? (
        <p>Employee has been added successfully!</p>
      ) : (
        <Container fluid={true}>
          {showUpdate ? (
            <React.Fragment>
              <Card>
                <CardHeader>
                  <Row>
                    <Col sm="10">
                      <h5>{EmployeeUpdateTitle}</h5>
                    </Col>
                    <Col sm="2">
                      <Button onClick={() => setShowUpdate(false)}>Back</Button>
                    </Col>
                  </Row>
                </CardHeader>
              </Card>
              {getComponentElement("18")}
            </React.Fragment>
          ) : (
            <Row>
              <Col sm="12">
                <Card>
                  <CardBody>
                    <Form
                      className="needs-validation"
                      noValidate=""
                      onSubmit={handleSubmitData}
                    >
                      <div className="search-component">
                        <div className="searchbox">
                          <Col md="12 mb-3">
                            <div className="form-group">
                              <input
                                type="text"
                                class="form-control search-input"
                                value={selectedSearchText}
                                onChange={handleSearchText}
                                placeholder={`Search...`}
                                onFocus={() => setSelectedSearchText("")}
                              />
                              <SearchIcon className="search-icon" />
                            </div>
                          </Col>
                        </div>
                      </div>
                      <Row>
                        <Col md="4 mb-3">
                          <FormGroup>
                            <Label>Category I</Label>
                            <Input
                              type="select"
                              className="custom-select"
                              required=""
                              value={selectedCategoryI}
                              onChange={handleChangeCategoryI}
                            >
                              <option value="0">{"Select..."}</option>
                              {dataCategoryI &&
                                dataCategoryI.length > 0 &&
                                dataCategoryI.map((category) => {
                                  return (
                                    <option value={category.CategoryId}>
                                      {category.CategoryName}
                                    </option>
                                  );
                                })}
                            </Input>
                            <div className="invalid-feedback">
                              {"Example invalid custom select feedback"}
                            </div>
                          </FormGroup>
                        </Col>
                        <Col md="4 mb-3">
                          <FormGroup>
                            <Label>Category II</Label>
                            <Input
                              type="select"
                              className="custom-select"
                              required=""
                              value={selectedCategoryII}
                              onChange={handleChangeCategoryII}
                            >
                              <option value="0">{"Select..."}</option>
                              {dataCategoryII &&
                                dataCategoryII.length > 0 &&
                                dataCategoryII.map((category) => {
                                  return (
                                    <option value={category.CategoryId}>
                                      {category.CategoryName}
                                    </option>
                                  );
                                })}
                            </Input>
                            <div className="invalid-feedback">
                              {"Example invalid custom select feedback"}
                            </div>
                          </FormGroup>
                        </Col>
                        <Col md="4 mb-3">
                          <FormGroup>
                            <Label>Category III</Label>
                            <Input
                              type="select"
                              className="custom-select"
                              required=""
                              value={selectedCategoryIII}
                              onChange={handleChangeCategoryIII}
                            >
                              <option value="0">{"Select..."}</option>
                              {dataCategoryIII &&
                                dataCategoryIII.length > 0 &&
                                dataCategoryIII.map((category) => {
                                  return (
                                    <option value={category.CategoryId}>
                                      {category.CategoryName}
                                    </option>
                                  );
                                })}
                            </Input>
                            <div className="invalid-feedback">
                              {"Example invalid custom select feedback"}
                            </div>
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <CommonDataGrid
                          allData={dataSelectedEmployees}
                          columnsData={columnsEmployees}
                          handleSelectionChanged={handleSelectionChanged}
                        />
                      </Row>
                    </Form>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          )}
        </Container>
      )}
    </Fragment>
  );
};

export default CommonSearch;
