import React from "react";
import { EmployeeUpdate } from "../EmployeeUpdate/EmployeeUpdate";

export const getComponentElement = (selectedCategory) => {
  switch (selectedCategory) {
    case "18":
      return <EmployeeUpdate />;
    // case "75":
    //   return <Events />;
    // case "88":
    //   return <Gallery />;
    // case "58":
    //   return <Placement />;
    // case "142":
    //   return <Courses />;
    // case "37":
    //   return <Admission />;
    // case "40":
    //   return <AboutUs />;
    // case "136":
    //   return <Contacts />;
    // case "204":
    //   return <RandD />;
    // case "62":
    //   return <Features />;
    // case "131":
    //   return <ExaminationCell />;
    // case "employeedetails":
    //   return <EmployeeDetails />;
    default:
      return <h1>Welcome to Nawab Shah Alam</h1>;
  }
};
