import React, { Fragment, useState, useEffect, useRef } from "react";
import { useHistory } from "react-router-dom";
import Breadcrumb from "../../../../layout/breadcrumb";
import { useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { uuid } from "uuidv4";
import { AccordionWithOpenandCloseIconSingle } from "../accordianComponentSingle";
import { Accordion } from "react-bootstrap";
import {
  Container,
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  Button,
  Form,
  FormGroup,
  Label,
  Input,
} from "reactstrap";
import { AffairsTitle, AffairsName, ChooseIcon } from "../../../../constant";
import {
  getAffairs,
  addAffairsDetails,
} from "../../../../services/subscriberService";
import { getAllAffairstype } from "../../../../state/selectors/affairs";
import { getAllCategories } from "../../../../state/selectors/categories";
import {
  subscriberRegistrationNo,
  profileImageFormat,
} from "../../../../config.json";

const AffairsAdd = () => {
  const { register, handleSubmit, errors } = useForm();
  const [contentAffairDescription, setContentAffairDescription] = useState("");
  const [savedSuccess, setSavedSuccess] = useState(false);
  const [selectedCategoryI, setSelectedCategoryI] = useState(0);
  const [selectedCategoryII, setSelectedCategoryII] = useState(0);
  const [selectedCategoryIII, setSelectedCategoryIII] = useState(0);
  const [selectedAffairstypes, setSelectedAffairstypes] = useState(0);
  const [dataSelectedAffair, setDataSelectedAffair] = useState([]);
  const [selectedAffairsID, setSelectedAffairsID] = useState("");
  const [selectedManager, setSelectedManager] = useState(0);
  const [selectedAffairsName, setSelectedAffairsName] = useState("");
  const [selectedRelativeUrl, setSelectedRelativeUrl] = useState("");
  const [selectedChooseIcon, setSelectedChooseIcon] = useState("");
  const [dataCategoryI, setDataCategoryI] = useState([]);
  const [dataCategoryII, setDataCategoryII] = useState([]);
  const [dataCategoryIII, setDataCategoryIII] = useState([]);
  const [name, setName] = useState([]);
  const allAffairstypes = useSelector(getAllAffairstype);
  const allCategories = useSelector(getAllCategories);
  const history = useHistory();

  const onSubmit = (data) => {
    if (data !== "") {
      alert("You submitted the form and stuff!");
    } else {
      errors.showMessages();
    }
  };
  const handleChangeCategoryI = (e) => {
    const filteredCategories =
      allCategories &&
      allCategories.filter((item) => item.ParentId == e.target.value);
    setDataCategoryII(filteredCategories);
    setSelectedCategoryI(e.target.value);
    setSelectedCategoryII(0);
    setSelectedCategoryIII(0);
  };
  const handleChangeCategoryII = (e) => {
    const filteredCategories =
      allCategories &&
      allCategories.filter((item) => item.ParentId == e.target.value);
    setDataCategoryIII(filteredCategories);
    setSelectedCategoryII(e.target.value);
    setSelectedCategoryIII(0);
  };

  const setAffairsDetails = async (affairstypeId) => {
    const affairssResult = await getAffairs(
      affairstypeId,
      subscriberRegistrationNo,
      ""
    );
    console.log("affairssResult--------------->", affairssResult);
    setDataSelectedAffair(affairssResult.data[0]);
  };
  const handleChangeCategoryIII = (e) => {
    setAffairsDetails("51e1a477-8fec-4bf6-a7c0-f7ac04737fe9");
    setSelectedCategoryIII(e.target.value);

    console.log(
      "e.target.value=aafair=>",
      e.target.value,
      "--",
      selectedCategoryI,
      "---",
      selectedCategoryII,
      ">>"
    );
  };
  const handleChangeManager = (e) => {
    setSelectedManager(e.target.value);
  };
  const handleChangeAffairstypes = (e) => {
    setSelectedAffairstypes(e.target.value);
  };
  const handleChangeRelativeUrl = (e) => {
    setSelectedRelativeUrl(e.target.value);
  };
  const saveAffairsData = async (buildRecords) => {
    const result = await addAffairsDetails(
      buildRecords,
      `${subscriberRegistrationNo}_${selectedAffairsID}${profileImageFormat}`
    );
    if (result.status === 200) {
      setSavedSuccess(true);
      history.push("/");
    }
  };
  const formatMailProductsList = (selectedProducts) => {
    let prodList = [];
    selectedProducts.forEach((element) => {
      prodList.push(element.label);
    });
    return prodList.join().replace(/,/g, ", ");
  };
  const handleSubmitData = (e) => {
    const prodToList = formatMailProductsList(name);
    const records = [
      {
        affairsId: selectedAffairsID,
        affairsName: selectedAffairsName,
        affairstypeId: selectedAffairstypes,
        relativeUrl: selectedRelativeUrl,
        iconPath: selectedChooseIcon,
        affairsDesc: contentAffairDescription,
        selectedCategoryI,
        selectedCategoryII,
        selectedCategoryIII,
        parentregistrationno: subscriberRegistrationNo,
        modifiedby: "Admin",
      },
    ];
    if (selectedAffairsName && selectedChooseIcon) {
      const buildRecords = JSON.stringify(records).replace(/"/g, '"');
      saveAffairsData(buildRecords);
    }
    e.preventDefault();
  };

  const handleChangeAffairsName = (e) => {
    setSelectedAffairsName(e.target.value);
  };
  const handleChangeChooseIcon = (e) => {
    setSelectedChooseIcon(e.target.value);
  };
  const handleContentKeyResponsibilties = (evt) => {
    const newContent = evt.editor.getData();
    setContentAffairDescription(newContent);
  };
  useEffect(() => {
    const filteredCategories =
      allCategories &&
      allCategories.filter(
        (item) => item.ParentId === null || item.ParentId === 0
      );
    setDataCategoryI(filteredCategories);
  }, [allCategories]);
  useEffect(() => {
    setSelectedAffairsID(uuid());
  }, []);

  return (
    <Fragment>
      <Breadcrumb parent="Form" />
      {savedSuccess ? (
        <p>Affairs has been added successfully!</p>
      ) : (
        <Container fluid={true}>
          <Row>
            <Col sm="12">
              <Card>
                <CardHeader>
                  <h5>{AffairsTitle}</h5>
                </CardHeader>
                <CardBody>
                  <Form
                    className="needs-validation"
                    noValidate=""
                    onSubmit={handleSubmitData}
                  >
                    <div className="form-row">
                      <Col md="4 mb-3">
                        <FormGroup>
                          <Label>Category I</Label>
                          <Input
                            type="select"
                            className="custom-select"
                            required=""
                            value={selectedCategoryI}
                            onChange={handleChangeCategoryI}
                          >
                            <option value="0">{"Select..."}</option>
                            {dataCategoryI &&
                              dataCategoryI.length > 0 &&
                              dataCategoryI.map((category) => {
                                return (
                                  <option value={category.CategoryId}>
                                    {category.CategoryName}
                                  </option>
                                );
                              })}
                          </Input>
                          <div className="invalid-feedback">
                            {"Example invalid custom select feedback"}
                          </div>
                        </FormGroup>
                      </Col>
                      <Col md="4 mb-3">
                        <FormGroup>
                          <Label>Category II</Label>
                          <Input
                            type="select"
                            className="custom-select"
                            required=""
                            value={selectedCategoryII}
                            onChange={handleChangeCategoryII}
                          >
                            <option value="0">{"Select..."}</option>
                            {dataCategoryII &&
                              dataCategoryII.length > 0 &&
                              dataCategoryII.map((category) => {
                                return (
                                  <option value={category.CategoryId}>
                                    {category.CategoryName}
                                  </option>
                                );
                              })}
                          </Input>
                          <div className="invalid-feedback">
                            {"Example invalid custom select feedback"}
                          </div>
                        </FormGroup>
                      </Col>
                      <Col md="4 mb-3">
                        <FormGroup>
                          <Label>Category III</Label>
                          <Input
                            type="select"
                            className="custom-select"
                            required=""
                            value={selectedCategoryIII}
                            onChange={handleChangeCategoryIII}
                          >
                            <option value="0">{"Select..."}</option>
                            {dataCategoryIII &&
                              dataCategoryIII.length > 0 &&
                              dataCategoryIII.map((category) => {
                                return (
                                  <option value={category.CategoryId}>
                                    {category.CategoryName}
                                  </option>
                                );
                              })}
                          </Input>
                          <div className="invalid-feedback">
                            {"Example invalid custom select feedback"}
                          </div>
                        </FormGroup>
                      </Col>
                    </div>
                    <div className="form-row">
                      <Col md="6 mb-3">
                        <FormGroup>
                          <Label>Affairs</Label>
                          <Input
                            type="select"
                            className="custom-select"
                            required=""
                            value={selectedAffairstypes}
                            onChange={handleChangeAffairstypes}
                          >
                            <option value="0">{"Select..."}</option>
                            {allAffairstypes &&
                              allAffairstypes.length > 0 &&
                              allAffairstypes.map((category) => {
                                return (
                                  <option value={category.affairstypeId}>
                                    {category.affairstypeName}
                                  </option>
                                );
                              })}
                          </Input>
                          <div className="invalid-feedback">
                            {"Example invalid custom select feedback"}
                          </div>
                        </FormGroup>
                      </Col>
                      <Col md="6 mb-3">
                        <Label htmlFor="validationCustom01">
                          {AffairsName}
                        </Label>
                        <Input
                          className="form-control"
                          name="affairsName"
                          type="text"
                          placeholder="Affairs Title"
                          innerRef={register({ required: true })}
                          onBlur={handleChangeAffairsName}
                        />
                        <span>
                          {errors.affairsName && "Affairs Name is required"}
                        </span>
                        <div className="valid-feedback">{"Looks good!"}</div>
                      </Col>
                    </div>
                    <div className="form-row">
                      <Col md="6 mb-3">
                        <Label htmlFor="validationCustom01">
                          {`Affair Url`}
                        </Label>
                        <Input
                          className="form-control"
                          name="relativeName"
                          type="text"
                          placeholder="Affairs Url"
                          innerRef={register({ required: true })}
                          onBlur={handleChangeRelativeUrl}
                        />
                        <span>
                          {errors.affairsName && "Affairs Url is required"}
                        </span>
                        <div className="valid-feedback">{"Looks good!"}</div>
                      </Col>
                      <Col md="6 mb-3">
                        <Label htmlFor="validationCustom02">{ChooseIcon}</Label>
                        <Input
                          className="form-control"
                          name="chooseIcon"
                          type="text"
                          placeholder="Choose Icon"
                          innerRef={register({ required: true })}
                          onBlur={handleChangeChooseIcon}
                        />
                        <span>
                          {errors.chooseIcon && "Last name is required"}
                        </span>
                        <div className="valid-feedback">{"Looks good!"}</div>
                      </Col>
                    </div>
                    <Col md="12 mb-3">
                      <Accordion defaultActiveKey="0">
                        <div
                          className="default-according style-1"
                          id="accordionoc"
                        >
                          <AccordionWithOpenandCloseIconSingle
                            title="Affair Description"
                            setContentData={handleContentKeyResponsibilties}
                            contentData={contentAffairDescription}
                          />
                        </div>
                      </Accordion>
                    </Col>
                    <Button color="primary">
                      <center>{"Submit form"}</center>
                    </Button>
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      )}
    </Fragment>
  );
};
export default AffairsAdd;
