import { Home, Anchor, Headphones } from "react-feather";
export const MENUITEMS = [
  {
    menutitle: "General",
    menucontent: "Dashboards Wizard",
    Items: [
      {
        title: "Dashboard",
        icon: Home,
        type: "sub",
        active: false,
        children: [
          {
            path: `${process.env.PUBLIC_URL}/dashboard/default`,
            title: "Default",
            type: "link",
          },
          // {
          //   path: `${process.env.PUBLIC_URL}/dashboard/ecommerce`,
          //   title: "Ecommerce",
          //   type: "link",
          // },
          // {
          //   path: `${process.env.PUBLIC_URL}/dashboard/aboutus`,
          //   title: "About Us",
          //   type: "link",
          // },
          // {
          //   path: `${process.env.PUBLIC_URL}/department/forms/form-control`,
          //   title: "Edit forms",
          //   type: "link",
          // },
          {
            path: `${process.env.PUBLIC_URL}/department/forms/affairsAdd`,
            title: "Add News/Events",
            type: "link",
          },
          {
            path: `${process.env.PUBLIC_URL}/department/forms/employeeAdd`,
            title: "Add Staff Member",
            type: "link",
          },
          {
            path: `${process.env.PUBLIC_URL}/department/forms/commonSearch`,
            title: "Update Staff Member",
            type: "link",
          },
          {
            path: `${process.env.PUBLIC_URL}/department/forms/homeleveldetailsAdd`,
            title: "Add Home Content",
            type: "link",
          },
          {
            path: `${process.env.PUBLIC_URL}/dashboard/addphotos`,
            title: "Add Photos PPT PDF etc",
            type: "link",
          },
          // {
          //   path: `${process.env.PUBLIC_URL}/dashboard/contactus`,
          //   title: "Contact Us",
          //   type: "link",
          // },
          // {
          //   path: `${process.env.PUBLIC_URL}/common/editgrid`,
          //   title: "Edit Grid",
          //   type: "link",
          // },
          // {
          //   path: `${process.env.PUBLIC_URL}/dashboard/topmenu`,
          //   title: "Top Menu",
          //   type: "link",
          // },
        ],
      },
    ],
  },
  // {
  //   menutitle: "Department",
  //   menucontent: "Ready to use Apps",
  //   Items: [
  //     {
  //       title: "Department",
  //       icon: Anchor,
  //       type: "sub",
  //       active: false,
  //       children: [
  //         {
  //           path: `${process.env.PUBLIC_URL}/department/sample-page`,
  //           title: "Sample Page",
  //           type: "link",
  //         },
  //       ],
  //     },
  //   ],
  // },
  // {
  //   menutitle: "Support",
  //   menucontent: "",
  //   Items: [
  //     {
  //       title: "Raise Support",
  //       icon: Headphones,
  //       type: "sub",
  //       active: false,
  //       children: [
  //         {
  //           title: "Raise Ticket",
  //           type: "exteral_link",
  //           path: "http://support.PickupBiz.com/help-center",
  //         },
  //       ],
  //     },
  //   ],
  // },
];
