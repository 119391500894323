// dashbaord
import Default from "../components/dashboard/default";
import Ecommerce from "../components/dashboard/ecommerce";
import CkEditor from "../components/dashboard/aboutus";
import ContactUs from "../components/dashboard/contactus";
import EditableGridView from "../components/common/editablegridview";
// starter kits
import department from "../components/department";
import TopMenu from "../components/dashboard/topmenu";
import FormValidation from "../components/department/forms/form-control";
import FormEmployeeAdd from "../components/department/forms/employeeAdd";
import FormAffairsAdd from "../components/department/forms/affairsAdd";
import FormCommonSearch from "../components/department/forms/commonSearch";
import { EmployeeUpdate } from "../components/department/forms/EmployeeUpdate/EmployeeUpdate";
import { HomeleveldetailsAdd } from "../components/department/forms/HomeleveldetailsAdd/HomeleveldetailsAdd";
import Gallery from "../components/dashboard/addphotos";
export const routes = [
  { path: `${process.env.PUBLIC_URL}/dashboard/default`, Component: Default },
  {
    path: `${process.env.PUBLIC_URL}/dashboard/ecommerce`,
    Component: Ecommerce,
  },
  { path: `${process.env.PUBLIC_URL}/dashboard/aboutus`, Component: CkEditor },
  {
    path: `${process.env.PUBLIC_URL}/dashboard/contactus`,
    Component: ContactUs,
  },
  {
    path: `${process.env.PUBLIC_URL}/department/sample-page`,
    Component: department,
  },
  {
    path: `${process.env.PUBLIC_URL}/common/editgrid`,
    Component: EditableGridView,
  },
  {
    path: `${process.env.PUBLIC_URL}/department/forms/form-control`,
    Component: FormValidation,
  },
  {
    path: `${process.env.PUBLIC_URL}/department/forms/employeeAdd`,
    Component: FormEmployeeAdd,
  },
  {
    path: `${process.env.PUBLIC_URL}/department/forms/affairsAdd`,
    Component: FormAffairsAdd,
  },
  {
    path: `${process.env.PUBLIC_URL}/department/forms/commonSearch`,
    Component: FormCommonSearch,
  },
  {
    path: `${process.env.PUBLIC_URL}/department/forms/employeeUpdate`,
    Component: EmployeeUpdate,
  },
  {
    path: `${process.env.PUBLIC_URL}/department/forms/homeleveldetailsAdd`,
    Component: HomeleveldetailsAdd,
  },
  { path: `${process.env.PUBLIC_URL}/common/topmenu`, Component: TopMenu },
  { path: `${process.env.PUBLIC_URL}/dashboard/addphotos`, Component: Gallery },
];
