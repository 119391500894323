import React, { Fragment, useState } from "react";
import RichTextBox from "../../dashboard/richtextbox";
import TextBoxWithLabel from "../../dashboard/textboxwithlabel";
import { Accordion } from "react-bootstrap";
import { Card, CardHeader, CardBody } from "reactstrap";

export const AccordianComponentWithTitle = ({
  setContentSectionDetails1,
  setContentSectionDetails2,
  setContentSectionDetails3,
  setContentSectionDetails4,
  setContentSectionDetails5,
  setContentSectionDetails6,
  setContentSectionDetails7,
  contentSectionDetails1,
  contentSectionDetails2,
  contentSectionDetails3,
  contentSectionDetails4,
  contentSectionDetails5,
  contentSectionDetails6,
  contentSectionDetails7,
  setChangeSectionTitle1,
  setChangeSectionTitle2,
  setChangeSectionTitle3,
  setChangeSectionTitle4,
  setChangeSectionTitle5,
  setChangeSectionTitle6,
  setChangeSectionTitle7,
}) => {
  const [content, setContent] = useState("content");
  const [expanded1, setexpanded1] = useState(true);
  const [expanded2, setexpanded2] = useState(false);
  const [expanded3, setexpanded3] = useState(false);
  const [expanded4, setexpanded4] = useState(false);
  const [expanded5, setexpanded5] = useState(false);
  const [expanded6, setexpanded6] = useState(false);
  const [expanded7, setexpanded7] = useState(false);
  const Accordion1 = () => {
    setexpanded1(!expanded1);
    setexpanded2(false);
    setexpanded3(false);
    setexpanded4(false);
    setexpanded5(false);
    setexpanded6(false);
    setexpanded7(false);
  };
  const Accordion2 = () => {
    setexpanded2(!expanded2);
    setexpanded1(false);
    setexpanded3(false);
    setexpanded4(false);
    setexpanded5(false);
    setexpanded6(false);
    setexpanded7(false);
  };
  const Accordion3 = () => {
    setexpanded3(!expanded3);
    setexpanded2(false);
    setexpanded1(false);
    setexpanded4(false);
    setexpanded5(false);
    setexpanded6(false);
    setexpanded7(false);
  };
  const Accordion4 = () => {
    setexpanded4(!expanded4);
    setexpanded1(false);
    setexpanded2(false);
    setexpanded3(false);
    setexpanded5(false);
    setexpanded6(false);
    setexpanded7(false);
  };
  const Accordion5 = () => {
    setexpanded5(!expanded5);
    setexpanded1(false);
    setexpanded2(false);
    setexpanded3(false);
    setexpanded4(false);
    setexpanded6(false);
    setexpanded7(false);
  };
  const Accordion6 = () => {
    setexpanded6(!expanded5);
    setexpanded1(false);
    setexpanded2(false);
    setexpanded3(false);
    setexpanded4(false);
    setexpanded5(false);
    setexpanded7(false);
  };
  const Accordion7 = () => {
    setexpanded7(!expanded5);
    setexpanded1(false);
    setexpanded2(false);
    setexpanded3(false);
    setexpanded4(false);
    setexpanded5(false);
    setexpanded6(false);
  };

  return (
    <Fragment>
      <Card>
        <CardHeader>
          <h5 className="mb-0">
            <Accordion.Toggle
              as={Card.Header}
              className="btn btn-link txt-white "
              color="primary"
              onClick={Accordion1}
              eventKey="1"
              aria-expanded={expanded1}
            >
              {"Section Details1"}
            </Accordion.Toggle>
          </h5>
        </CardHeader>
        <Accordion.Collapse eventKey="1">
          <CardBody>
            <TextBoxWithLabel
              setContent={setChangeSectionTitle1}
              contentId="sectionTitle1"
              contentTitle="Section Title 1"
            />
            <RichTextBox
              setContent={setContentSectionDetails1}
              content={contentSectionDetails1}
            />
          </CardBody>
        </Accordion.Collapse>
      </Card>
      <Card>
        <CardHeader>
          <h5 className="mb-0">
            <Accordion.Toggle
              as={Card.Header}
              className="btn btn-link txt-white "
              color="primary"
              onClick={Accordion2}
              eventKey="2"
              aria-expanded={expanded1}
            >
              {"Section Details2"}
            </Accordion.Toggle>
          </h5>
        </CardHeader>
        <Accordion.Collapse eventKey="2">
          <CardBody>
            <TextBoxWithLabel
              setContent={setChangeSectionTitle2}
              contentId="sectionTitle2"
              contentTitle="Section Title 2"
            />
            <RichTextBox
              setContent={setContentSectionDetails2}
              content={contentSectionDetails2}
            />
          </CardBody>
        </Accordion.Collapse>
      </Card>
      <Card>
        <CardHeader>
          <h5 className="mb-0">
            <Accordion.Toggle
              as={Card.Header}
              className="btn btn-link txt-white "
              color="primary"
              onClick={Accordion3}
              eventKey="3"
              aria-expanded={expanded3}
            >
              {"Section Details3"}
            </Accordion.Toggle>
          </h5>
        </CardHeader>
        <Accordion.Collapse eventKey="3">
          <CardBody>
            <TextBoxWithLabel
              setContent={setChangeSectionTitle3}
              contentId="sectionTitle3"
              contentTitle="Section Title 3"
            />
            <RichTextBox
              setContent={setContentSectionDetails3}
              content={contentSectionDetails3}
            />
          </CardBody>
        </Accordion.Collapse>
      </Card>
      <Card>
        <CardHeader>
          <h5 className="mb-0">
            <Accordion.Toggle
              as={Card.Header}
              className="btn btn-link txt-white "
              color="primary"
              onClick={Accordion4}
              eventKey="4"
              aria-expanded={expanded4}
            >
              {"Section Details4"}
            </Accordion.Toggle>
          </h5>
        </CardHeader>
        <Accordion.Collapse eventKey="4">
          <CardBody>
            <TextBoxWithLabel
              setContent={setChangeSectionTitle4}
              contentId="sectionTitle4"
              contentTitle="Section Title 4"
            />
            <RichTextBox
              setContent={setContentSectionDetails4}
              content={contentSectionDetails4}
            />
          </CardBody>
        </Accordion.Collapse>
      </Card>
      <Card>
        <CardHeader>
          <h5 className="mb-0">
            <Accordion.Toggle
              as={Card.Header}
              className="btn btn-link txt-white "
              color="primary"
              onClick={Accordion5}
              eventKey="5"
              aria-expanded={expanded4}
            >
              {"Section Details5"}
            </Accordion.Toggle>
          </h5>
        </CardHeader>
        <Accordion.Collapse eventKey="5">
          <CardBody>
            <TextBoxWithLabel
              setContent={setChangeSectionTitle5}
              contentId="sectionTitle5"
              contentTitle="Section Title 5"
            />
            <RichTextBox
              setContent={setContentSectionDetails5}
              content={contentSectionDetails5}
            />
          </CardBody>
        </Accordion.Collapse>
      </Card>
      <Card>
        <CardHeader>
          <h5 className="mb-0">
            <Accordion.Toggle
              as={Card.Header}
              className="btn btn-link txt-white "
              color="primary"
              onClick={Accordion6}
              eventKey="6"
              aria-expanded={expanded6}
            >
              {"Section Details6"}
            </Accordion.Toggle>
          </h5>
        </CardHeader>
        <Accordion.Collapse eventKey="6">
          <CardBody>
            <TextBoxWithLabel
              setContent={setChangeSectionTitle6}
              contentId="sectionTitle6"
              contentTitle="Section Title 6"
            />
            <RichTextBox
              setContent={setContentSectionDetails6}
              content={contentSectionDetails6}
            />
          </CardBody>
        </Accordion.Collapse>
      </Card>
      <Card>
        <CardHeader>
          <h5 className="mb-0">
            <Accordion.Toggle
              as={Card.Header}
              className="btn btn-link txt-white "
              color="primary"
              onClick={Accordion7}
              eventKey="7"
              aria-expanded={expanded7}
            >
              {"Section Details7"}
            </Accordion.Toggle>
          </h5>
        </CardHeader>
        <Accordion.Collapse eventKey="7">
          <CardBody>
            <TextBoxWithLabel
              setContent={setChangeSectionTitle7}
              contentId="sectionTitle7"
              contentTitle="Section Title 7"
            />
            <RichTextBox
              setContent={setContentSectionDetails7}
              content={contentSectionDetails7}
            />
          </CardBody>
        </Accordion.Collapse>
      </Card>
    </Fragment>
  );
};
