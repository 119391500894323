import React, { Fragment, useState, useEffect, useRef } from "react";
import { useHistory } from "react-router-dom";
import Breadcrumb from "../../../../layout/breadcrumb";
import { useSelector, useDispatch } from "react-redux";
import { useForm } from "react-hook-form";
import DatePicker from "react-datepicker";
import { AccordionWithOpenandCloseIcon } from "../accordianComponent";
import { Accordion } from "react-bootstrap";
import MultiSelect from "react-multi-select-component";
import TooltipForm from "../tooltipForm";
import "react-datepicker/dist/react-datepicker.css";
import {
  Container,
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  Button,
  Form,
  FormGroup,
  Label,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
} from "reactstrap";
import {
  FirstName,
  LastName,
  EmployeeBrowserDefaults,
  ooltips,
  Username,
  State,
  City,
  Zip,
  SupportedElements,
  EmployeeSubmitForm,
} from "../../../../constant";
import {
  getEmployees,
  addEmployeeDetails,
  updateEmployeeDetails,
} from "../../../../services/subscriberService";
import { ConfirmYesNo } from "../../../common/ConfirmYesNo";
import { getAllGenders } from "../../../../state/selectors/genders";
import { getAllMemberships } from "../../../../state/selectors/memberships";
import { getAllDesignations } from "../../../../state/selectors/designations";
import { getAllPrefix } from "../../../../state/selectors/prefix";
import {
  getSelectedEmployee,
  getSelectedemployeePicture,
} from "../../../../state/selectors/employees";
import { getAllCategories } from "../../../../state/selectors/categories";
import { setSelctedEmployeePicture } from "../../../../state/actions/employees";
import {
  subscriberRegistrationNo,
  profileImageFormat,
} from "../../../../config.json";
import { UploadSingleFileUpdate } from "../../../common/UploadSingleFileUpdate";

export const EmployeeUpdate = () => {
  const { register, handleSubmit, errors } = useForm();
  const dispatch = useDispatch();
  const [progressValue, setProgressValue] = useState();
  const [contentKeyResponsibilties, setContentKeyResponsibilties] = useState(
    ""
  );
  const [contentBiography, setContentBiography] = useState("");
  const [
    contentResearchAndPublications,
    setContentResearchAndPublications,
  ] = useState("");
  const [contentConference, setContentConference] = useState("");
  const [contentPaperPresentation, setContentPaperPresentation] = useState("");
  const [selectedStartDate, setSelectedStartDate] = useState(new Date());
  const [savedSuccess, setSavedSuccess] = useState(false);
  const [selectedCategoryI, setSelectedCategoryI] = useState(0);
  const [selectedCategoryII, setSelectedCategoryII] = useState(0);
  const [selectedCategoryIII, setSelectedCategoryIII] = useState(0);
  const [selectedPrefix, setSelectedPrefix] = useState(0);
  const [selectedMemberships, setSelectedMemberships] = useState(0);
  const [selectedDesignation, setSelectedDesignation] = useState(0);
  const [selectedEmployeeID, setSelectedEmployeeID] = useState("");
  const [selectedJobID, setSelectedJobID] = useState(0);
  const [selectedGender, setSelectedGender] = useState(0);
  const [selectedManager, setSelectedManager] = useState(0);
  const [selectedFirstName, setSelectedFirstName] = useState("");
  const [selectedLastName, setSelectedLastName] = useState("");
  const [selectedPhoneNumber, setSelectedPhoneNumber] = useState("");
  const [selectedEmail, setSelectedEmail] = useState("");
  const [
    selectedHighestQualification,
    setSelectedHighestQualification,
  ] = useState("");
  const [selectedOtherQualification, setSelectedOtherQualification] = useState(
    ""
  );
  const [selectedExperienceInYears, setSelectedExperienceInYears] = useState(
    ""
  );
  const [dataCategoryI, setDataCategoryI] = useState([]);
  const [dataCategoryII, setDataCategoryII] = useState([]);
  const [dataCategoryIII, setDataCategoryIII] = useState([]);
  const [dataSelectedEmployees, setDataSelectedEmployees] = useState([]);
  const [isDeleteEmployee, setIsDeleteEmployee] = useState(false);
  const [name, setName] = useState([]);
  const [optionsProducts, setOptionsProducts] = useState([
    { label: "", value: "" },
  ]);
  const allGenders = useSelector(getAllGenders);
  const allMemberships = useSelector(getAllMemberships);
  const allDesignations = useSelector(getAllDesignations);
  const selectedEmployeeGUID = useSelector(getSelectedEmployee);
  const allPrefix = useSelector(getAllPrefix);
  const allCategories = useSelector(getAllCategories);
  const selectorEmployeePicture = useSelector(getSelectedemployeePicture);
  const history = useHistory();
  const handleChangeStartDate = (date) => {
    setSelectedStartDate(date);
  };
  const onSubmit = (data) => {
    if (data !== "") {
      alert("You submitted the form and stuff!");
    } else {
      errors.showMessages();
    }
  };
  const handleChangeCategoryI = (value) => {
    const filteredCategories =
      allCategories && allCategories.filter((item) => item.ParentId == value);
    setDataCategoryII(filteredCategories);
    setSelectedCategoryI(value);
    setSelectedCategoryII(0);
    setSelectedCategoryIII(0);
  };
  const handleChangeCategoryII = (value) => {
    const filteredCategories =
      allCategories && allCategories.filter((item) => item.ParentId == value);
    setDataCategoryIII(filteredCategories);
    setSelectedCategoryII(value);
    setSelectedCategoryIII(0);
  };

  const setEmployeeDetails = async (catId) => {
    const employeesResult = await getEmployees(
      catId,
      subscriberRegistrationNo,
      ""
    );
    setDataSelectedEmployees(employeesResult.data[0]);
  };
  const handleChangeCategoryIII = (value) => {
    setEmployeeDetails(value);
    setSelectedCategoryIII(value);
  };
  const handleChangeManager = (e) => {
    setSelectedManager(e.target.value);
  };
  const handleChangePrefix = (e) => {
    setSelectedPrefix(e.target.value);
  };
  const saveEmployeeData = async (buildRecords) => {
    const result = await updateEmployeeDetails(
      buildRecords,
      selectorEmployeePicture
    );
    if (result.status === 200) {
      setSavedSuccess(true);
      history.push("/");
    }
  };
  const formatMailProductsList = (selectedProducts) => {
    let prodList = [];
    selectedProducts.forEach((element) => {
      prodList.push(element.label);
    });
    return prodList.join().replace(/,/g, ", ");
  };
  const handleSubmitData = (e) => {
    const prodToList = formatMailProductsList(name);
    const records = [
      {
        parentregistrationno: subscriberRegistrationNo,
        contentKeyResponsibilties,
        contentBiography,
        contentResearchAndPublications,
        contentConference,
        contentPaperPresentation,
        selectedStartDate,
        selectedCategoryI,
        selectedCategoryII,
        selectedCategoryIII,
        selectedPrefix,
        selectedMemberships: prodToList,
        selectedDesignation,
        selectedGender,
        selectedManager,
        selectedFirstName,
        selectedLastName,
        selectedUploadPhoto: selectorEmployeePicture,
        selectedPhoneNumber,
        selectedEmail,
        selectedHighestQualification,
        selectedOtherQualification,
        selectedEmployeeID: selectedEmployeeGUID.EmpID,
        selectedJobID,
        selectedExperienceInYears,
        modifiedby: "Admin",
        istransaction: "true",
      },
    ];
    if (selectedFirstName && selectedLastName) {
      const buildRecords = JSON.stringify(records).replace(/"/g, '"');
      saveEmployeeData(buildRecords);
    }
    e.preventDefault();
  };
  const handleChangeMemberships = (e) => {
    setSelectedMemberships(e.target.value);
    e.stopPropagation();
  };
  const handleChangeDesignation = (e) => {
    setSelectedDesignation(e.target.value);
    e.stopPropagation();
  };
  const handleChangeGender = (e) => {
    setSelectedGender(e.target.value);
    e.stopPropagation();
  };
  const handleChangeFirstName = (e) => {
    setSelectedFirstName(e.target.value);
    e.stopPropagation();
  };
  const handleChangeMemberships2 = (e) => {
    setSelectedMemberships(e.target.value);
    e.stopPropagation();
  };
  const handleChangeLastName = (e) => {
    setSelectedLastName(e.target.value);
    e.stopPropagation();
  };
  // const handleChangeUploadPhoto = e =>{
  //   console.log("e.target.files[0]=======>",e.target.files[0].name)
  //   setSelectedUploadPhoto(e.target.files[0]);
  // }
  const handleChangePhoneNumber = (e) => {
    setSelectedPhoneNumber(e.target.value);
    e.stopPropagation();
  };
  const handleChangeEmail = (e) => {
    setSelectedEmail(e.target.value);
    e.stopPropagation();
  };
  const handleChangeHighestQualification = (e) => {
    setSelectedHighestQualification(e.target.value);
    e.stopPropagation();
  };
  const handleChangeOtherQualification = (e) => {
    setSelectedOtherQualification(e.target.value);
    e.stopPropagation();
  };
  const handleChangeJobID = (e) => {
    setSelectedJobID(e.target.value);
    e.stopPropagation();
  };
  const handleChangeExperienceInYears = (e) => {
    setSelectedExperienceInYears(e.target.value);
    e.stopPropagation();
  };
  const handleContentBiography = (evt) => {
    const newContent = evt.editor.getData();
    setContentBiography(newContent);
  };
  const handleContentConference = (evt) => {
    const newContent = evt.editor.getData();
    setContentConference(newContent);
  };
  const handleContentKeyResponsibilties = (evt) => {
    const newContent = evt.editor.getData();
    setContentKeyResponsibilties(newContent);
  };
  const handleContentPaperPresentation = (evt) => {
    const newContent = evt.editor.getData();
    setContentPaperPresentation(newContent);
  };
  const handleContentResearchAndPublications = (evt) => {
    const newContent = evt.editor.getData();
    setContentResearchAndPublications(newContent);
  };

  const setCategoriesData = async () => {
    await handleChangeCategoryI(selectedEmployeeGUID.categorylevel1);
    await handleChangeCategoryII(selectedEmployeeGUID.categorylevel2);
    await handleChangeCategoryIII(selectedEmployeeGUID.categorylevel3);
    setSelectedManager(selectedEmployeeGUID.ManagerId);
  };
  useEffect(() => {
    dispatch(setSelctedEmployeePicture(selectedEmployeeGUID.picUrl));
    setSelectedPrefix(selectedEmployeeGUID.prefix);
    setSelectedFirstName(selectedEmployeeGUID.FirstName);
    setSelectedLastName(selectedEmployeeGUID.LastName);
    setSelectedPhoneNumber(selectedEmployeeGUID.PhoneNumber);
    setSelectedEmail(selectedEmployeeGUID.Email);
    setSelectedGender(selectedEmployeeGUID.GenderId);

    setSelectedJobID(selectedEmployeeGUID.JobID);
    setSelectedPrefix(selectedEmployeeGUID.prefixId);
    setSelectedHighestQualification(selectedEmployeeGUID.highestQualification);
    setSelectedOtherQualification(selectedEmployeeGUID.otherQualification);
    setSelectedStartDate(Date.parse(selectedEmployeeGUID.HireDate));
    setSelectedDesignation(selectedEmployeeGUID.DesignationID);
    setSelectedMemberships(selectedEmployeeGUID.MembershipIds);
    setCategoriesData();
    setSelectedExperienceInYears(
      selectedEmployeeGUID.ExperienceAtTimeOfJoining
    );

    setContentKeyResponsibilties(
      selectedEmployeeGUID.KeyResposibility.replace(/\\n/g, "").replace(
        /\\t/g,
        ""
      )
    );
    setContentBiography(
      selectedEmployeeGUID.Boigraphy.replace(/\\n/g, "").replace(/\\t/g, "")
    );
    setContentConference(
      selectedEmployeeGUID.Conference.replace(/\\n/g, "").replace(/\\t/g, "")
    );
    setContentPaperPresentation(
      selectedEmployeeGUID.PaperPresentation.replace(/\\n/g, "").replace(
        /\\t/g,
        ""
      )
    );
    setContentResearchAndPublications(
      selectedEmployeeGUID.ResearchAndPublication.replace(/\\n/g, "").replace(
        /\\t/g,
        ""
      )
    );
  }, [selectedEmployeeGUID]);
  useEffect(() => {
    if (isDeleteEmployee) {
      const records = [
        {
          parentregistrationno: subscriberRegistrationNo,
          selectedEmployeeID: selectedEmployeeGUID.EmpID,
          isDeleted: "true",
          isActive: 0,
        },
      ];
      const buildRecords = JSON.stringify(records).replace(/"/g, '"');
      saveEmployeeData(buildRecords);
    }
  }, [isDeleteEmployee]);
  useEffect(() => {
    const filteredCategories =
      allCategories &&
      allCategories.filter(
        (item) => item.ParentId === null || item.ParentId === 0
      );
    setDataCategoryI(filteredCategories);
  }, [allCategories]);

  useEffect(() => {
    const arrData = [];
    allMemberships &&
      allMemberships.forEach((element) => {
        element["label"] = element["name"].replace(/&amp;/g, "&");
        element["value"] = element["id"];
        arrData.push(element);
      });
    setOptionsProducts(arrData);
  }, [allMemberships]);
  return (
    <Fragment>
      {savedSuccess ? (
        <p>Employee has been added successfully!</p>
      ) : (
        <Container fluid={true}>
          <Row>
            <Col sm="12">
              <Card>
                <CardBody>
                  <Form
                    className="needs-validation"
                    noValidate=""
                    onSubmit={handleSubmitData}
                  >
                    <div className="form-row">
                      <Col md="4 mb-3">
                        <FormGroup>
                          <Label>Category I</Label>
                          <Input
                            type="select"
                            className="custom-select"
                            disabled
                            required=""
                            value={selectedCategoryI}
                            onChange={(e) =>
                              handleChangeCategoryI(e.target.value)
                            }
                          >
                            <option value="0">{"Select..."}</option>
                            {dataCategoryI &&
                              dataCategoryI.length > 0 &&
                              dataCategoryI.map((category) => {
                                return (
                                  <option value={category.CategoryId}>
                                    {category.CategoryName}
                                  </option>
                                );
                              })}
                          </Input>
                          <div className="invalid-feedback">
                            {"Example invalid custom select feedback"}
                          </div>
                        </FormGroup>
                      </Col>
                      <Col md="4 mb-3">
                        <FormGroup>
                          <Label>Category II</Label>
                          <Input
                            type="select"
                            disabled
                            className="custom-select"
                            required=""
                            value={selectedCategoryII}
                            onChange={handleChangeCategoryII}
                          >
                            <option value="0">{"Select..."}</option>
                            {dataCategoryII &&
                              dataCategoryII.length > 0 &&
                              dataCategoryII.map((category) => {
                                return (
                                  <option value={category.CategoryId}>
                                    {category.CategoryName}
                                  </option>
                                );
                              })}
                          </Input>
                          <div className="invalid-feedback">
                            {"Example invalid custom select feedback"}
                          </div>
                        </FormGroup>
                      </Col>
                      <Col md="4 mb-3">
                        <FormGroup>
                          <Label>Category III</Label>
                          <Input
                            type="select"
                            disabled
                            className="custom-select"
                            required=""
                            value={selectedCategoryIII}
                            onChange={handleChangeCategoryIII}
                          >
                            <option value="0">{"Select..."}</option>
                            {dataCategoryIII &&
                              dataCategoryIII.length > 0 &&
                              dataCategoryIII.map((category) => {
                                return (
                                  <option value={category.CategoryId}>
                                    {category.CategoryName}
                                  </option>
                                );
                              })}
                          </Input>
                          <div className="invalid-feedback">
                            {"Example invalid custom select feedback"}
                          </div>
                        </FormGroup>
                      </Col>
                    </div>
                    <div className="form-row">
                      <Col md="4 mb-3">
                        <FormGroup>
                          <Label>Prefix</Label>
                          <Input
                            type="select"
                            className="custom-select"
                            required=""
                            value={selectedPrefix}
                            onChange={handleChangePrefix}
                          >
                            <option value="0">{"Select..."}</option>
                            {allPrefix &&
                              allPrefix.length > 0 &&
                              allPrefix.map((category) => {
                                return (
                                  <option value={category.id}>
                                    {category.name}
                                  </option>
                                );
                              })}
                          </Input>
                          <div className="invalid-feedback">
                            {"Example invalid custom select feedback"}
                          </div>
                        </FormGroup>
                      </Col>
                      <Col md="4 mb-3">
                        <Label htmlFor="validationCustom01">{FirstName}</Label>
                        <Input
                          className="form-control"
                          name="firstName"
                          type="text"
                          value={selectedFirstName}
                          placeholder="First name"
                          innerRef={register({ required: true })}
                          onChange={handleChangeFirstName}
                        />
                        <span>
                          {errors.firstName && "First name is required"}
                        </span>
                        <div className="valid-feedback">{"Looks good!"}</div>
                      </Col>
                      <Col md="4 mb-3">
                        <Label htmlFor="validationCustom02">{LastName}</Label>
                        <Input
                          className="form-control"
                          name="lastName"
                          value={selectedLastName}
                          type="text"
                          placeholder="Last name"
                          innerRef={register({ required: true })}
                          onChange={handleChangeLastName}
                        />
                        <span>
                          {errors.lastName && "Last name is required"}
                        </span>
                        <div className="valid-feedback">{"Looks good!"}</div>
                      </Col>
                    </div>
                    <div className="form-row">
                      <Col md="4 mb-3">
                        <UploadSingleFileUpdate
                          selectedEmployeeID={selectedEmployeeGUID.EmpID}
                          setProgressValue={setProgressValue}
                        />
                      </Col>
                      <Col md="4 mb-3">
                        <Label htmlFor="validationCustom03">Email</Label>
                        <Input
                          className="form-control"
                          name="email"
                          type="email"
                          value={selectedEmail}
                          placeholder="Email"
                          innerRef={register({ required: true })}
                          onBlur={handleChangeEmail}
                        />
                        <span>
                          {errors.email && "Please provide a valid email-id"}
                        </span>
                        <div className="invalid-feedback">
                          {"Please provide a valid email-id."}
                        </div>
                      </Col>
                      <Col md="4 mb-3">
                        <Label htmlFor="validationCustom04">Phone Number</Label>
                        <Input
                          className="form-control"
                          id="validationCustom04"
                          name="phonenumber"
                          type="number"
                          value={selectedPhoneNumber}
                          placeholder="Phone Number"
                          innerRef={register({ required: true })}
                          onBlur={handleChangePhoneNumber}
                        />
                        <span>
                          {errors.number && "Please provide a valid number."}
                        </span>
                        <div className="invalid-feedback">
                          {"Please provide a valid number."}
                        </div>
                      </Col>
                    </div>
                    <div className="form-row">
                      <Col md="4 mb-3">
                        <Label htmlFor="validationCustom05">Job ID</Label>
                        <Input
                          className="form-control"
                          id="validationCustom05"
                          name="jobid"
                          type="number"
                          disabled
                          value={selectedJobID}
                          placeholder="Job ID"
                          innerRef={register({ required: true })}
                          onBlur={handleChangeJobID}
                        />
                        <span>
                          {errors.exp && "Please provide a valid zip."}
                        </span>
                        <div className="invalid-feedback">
                          {"Please provide a valid zip."}
                        </div>
                      </Col>

                      <Col md="4 mb-3">
                        <Label htmlFor="validationCustom05">
                          Highest Qualification
                        </Label>
                        <Input
                          className="form-control"
                          id="validationCustom05"
                          name="highestqualification"
                          value={selectedHighestQualification}
                          type="text"
                          placeholder="Highest Qualification"
                          innerRef={register({ required: true })}
                          onBlur={handleChangeHighestQualification}
                        />
                        <span>
                          {errors.exp && "Please provide a valid zip."}
                        </span>
                        <div className="invalid-feedback">
                          {"Please provide a valid zip."}
                        </div>
                      </Col>
                      <Col md="4 mb-3">
                        <Label htmlFor="validationCustom05">
                          Other Qualification
                        </Label>
                        <Input
                          className="form-control"
                          id="validationCustom05"
                          name="otherqualification"
                          value={selectedOtherQualification}
                          type="text"
                          placeholder="Other Qualification"
                          innerRef={register({ required: true })}
                          onBlur={handleChangeOtherQualification}
                        />
                        <span>
                          {errors.exp && "Please provide a valid zip."}
                        </span>
                        <div className="invalid-feedback">
                          {"Please provide a valid zip."}
                        </div>
                      </Col>
                    </div>
                    <div className="form-row">
                      <Col md="4 mb-3">
                        <Label htmlFor="validationCustom05">
                          Experience(in years)
                        </Label>
                        <Input
                          className="form-control"
                          id="validationCustom05"
                          name="experience"
                          type="number"
                          value={selectedExperienceInYears}
                          innerRef={register({ required: true })}
                          onBlur={handleChangeExperienceInYears}
                        />
                        <span>
                          {errors.exp && "Please provide a valid zip."}
                        </span>
                        <div className="invalid-feedback">
                          {"Please provide a valid zip."}
                        </div>
                      </Col>
                      <Col md="4 mb-3">
                        <Label>Hire Date</Label>
                        <div className="date-picker">
                          <Form className="theme-form">
                            <FormGroup className="form-row">
                              <div className="input-group">
                                <DatePicker
                                  className="form-control digits"
                                  selected={selectedStartDate}
                                  onChange={handleChangeStartDate}
                                />
                              </div>
                            </FormGroup>
                          </Form>
                        </div>
                      </Col>
                      <Col md="4 mb-3">
                        <FormGroup>
                          <Label>Memberships</Label>
                          <Input
                            className="form-control"
                            name="memberships"
                            type="text"
                            value={selectedMemberships}
                            placeholder="Memberships name"
                            onChange={handleChangeMemberships2}
                          />
                        </FormGroup>
                      </Col>
                      <Col md="4 mb-3">
                        <FormGroup>
                          <Label>Designation</Label>
                          <Input
                            type="select"
                            className="custom-select"
                            required=""
                            value={selectedDesignation}
                            onChange={handleChangeDesignation}
                          >
                            <option value="0">{"Select..."}</option>
                            {allDesignations &&
                              allDesignations.length > 0 &&
                              allDesignations.map((design) => {
                                return (
                                  <option value={design.id}>
                                    {design.name}
                                  </option>
                                );
                              })}
                          </Input>
                          <div className="invalid-feedback">
                            {"Example invalid custom select feedback"}
                          </div>
                        </FormGroup>
                      </Col>
                      <Col md="4 mb-3">
                        <FormGroup>
                          <Label>Gender</Label>
                          <Input
                            type="select"
                            className="custom-select"
                            required=""
                            value={selectedGender}
                            onChange={handleChangeGender}
                          >
                            <option value="0">{"Select..."}</option>
                            {allGenders &&
                              allGenders.length > 0 &&
                              allGenders.map((gender) => {
                                return (
                                  <option value={gender.id}>
                                    {gender.name}
                                  </option>
                                );
                              })}
                          </Input>
                          <div className="invalid-feedback">
                            {"Example invalid custom select feedback"}
                          </div>
                        </FormGroup>
                      </Col>
                      <Col md="4 mb-3">
                        <FormGroup>
                          <Label>Manager</Label>
                          <Input
                            type="select"
                            className="custom-select"
                            required=""
                            value={selectedManager}
                            onChange={handleChangeManager}
                          >
                            <option value="0">{"Select..."}</option>
                            {dataSelectedEmployees &&
                              dataSelectedEmployees.length > 0 &&
                              dataSelectedEmployees.map((emp) => {
                                return (
                                  <option
                                    value={emp.EmpID}
                                  >{`${emp.prefix} ${emp.FirstName} ${emp.LastName}`}</option>
                                );
                              })}
                          </Input>
                          <div className="invalid-feedback">
                            {"Example invalid custom select feedback"}
                          </div>
                        </FormGroup>
                      </Col>
                    </div>
                    <Col md="12 mb-3">
                      <Accordion defaultActiveKey="0">
                        <div
                          className="default-according style-1"
                          id="accordionoc"
                        >
                          <AccordionWithOpenandCloseIcon
                            setContentKeyResponsibilties={
                              handleContentKeyResponsibilties
                            }
                            setContentBiography={handleContentBiography}
                            setContentResearchAndPublications={
                              handleContentResearchAndPublications
                            }
                            setContentConference={handleContentConference}
                            setContentPaperPresentation={
                              handleContentPaperPresentation
                            }
                            contentKeyResponsibilties={
                              contentKeyResponsibilties
                            }
                            contentBiography={contentBiography}
                            contentResearchAndPublications={
                              contentResearchAndPublications
                            }
                            contentConference={contentConference}
                            contentPaperPresentation={contentPaperPresentation}
                          />
                        </div>
                      </Accordion>
                    </Col>
                    <Col>
                      <Row>
                        <Col lg={4}>
                          <ConfirmYesNo
                            buttonLable="Delete Employee"
                            messageTitle="Delete Employee"
                            messageLabel={`Do you want to delete ${selectedFirstName} ${selectedLastName} employee?`}
                            setYesNo={setIsDeleteEmployee}
                          />
                        </Col>
                        <Col lg={4}>
                          <Button color="primary">
                            <center>{"Submit form"}</center>
                          </Button>
                        </Col>
                      </Row>
                    </Col>
                    =
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      )}
    </Fragment>
  );
};
