export const OLYMPIC_SPORTS = [
    "Swimming",
    "Gymnastics",
    "Speed Skating",
    "Cross Country Skiing",
    "Short-Track Speed Skating",
    "Diving",
    "Cycling",
    "Biathlon",
    "Alpine Skiing",
    "Ski Jumping",
    "Nordic Combined",
    "Athletics",
    "Table Tennis",
    "Tennis",
    "Synchronized Swimming",
    "Shooting",
    "Rowing",
    "Fencing",
    "Equestrian",
    "Canoeing",
    "Bobsleigh",
    "Badminton",
    "Archery",
    "Wrestling",
    "Weightlifting",
    "Waterpolo",
    "Volleyball",
    "Triathlon",
    "Trampoline",
    "Taekwondo",
    "Softball",
    "Snowboarding",
    "Skeleton",
    "Sailing",
    "Rhythmic Gymnastics",
    "Modern Pentathlon",
    "Luge",
    "Judo",
    "Ice Hockey",
    "Hockey",
    "Handball",
    "Football",
    "Figure Skating",
    "Freestyle Skiing",
    "Curling",
    "Baseball",
    "Boxing",
    "Beach Volleyball",
    "Basketball"
];

export const OLYMPIC_COUNTRIES = [
    "United States",
    "Russia",
    "Australia",
    "Canada",
    "Norway",
    "China",
    "Zimbabwe",
    "Netherlands",
    "South Korea",
    "Croatia",
    "France",
    "Japan",
    "Hungary",
    "Germany",
    "Poland",
    "South Africa",
    "Sweden",
    "Ukraine",
    "Italy",
    "Czech Republic",
    "Austria",
    "Finland",
    "Romania",
    "Great Britain",
    "Jamaica",
    "Singapore",
    "Belarus",
    "Chile",
    "Spain",
    "Tunisia",
    "Brazil",
    "Slovakia",
    "Costa Rica",
    "Bulgaria",
    "Switzerland",
    "New Zealand",
    "Estonia",
    "Kenya",
    "Ethiopia",
    "Trinidad and Tobago",
    "Turkey",
    "Morocco",
    "Bahamas",
    "Slovenia",
    "Armenia",
    "Azerbaijan",
    "India",
    "Puerto Rico",
    "Egypt",
    "Kazakhstan",
    "Iran",
    "Georgia",
    "Lithuania",
    "Cuba",
    "Colombia",
    "Mongolia",
    "Uzbekistan",
    "North Korea",
    "Tajikistan",
    "Kyrgyzstan",
    "Greece",
    "Macedonia",
    "Moldova",
    "Chinese Taipei",
    "Indonesia",
    "Thailand",
    "Vietnam",
    "Latvia",
    "Venezuela",
    "Mexico",
    "Nigeria",
    "Qatar",
    "Serbia",
    "Serbia and Montenegro",
    "Hong Kong",
    "Denmark",
    "Portugal",
    "Argentina",
    "Afghanistan",
    "Gabon",
    "Dominican Republic",
    "Belgium",
    "Kuwait",
    "United Arab Emirates",
    "Cyprus",
    "Israel",
    "Algeria",
    "Montenegro",
    "Iceland",
    "Paraguay",
    "Cameroon",
    "Saudi Arabia",
    "Ireland",
    "Malaysia",
    "Uruguay",
    "Togo",
    "Mauritius",
    "Syria",
    "Botswana",
    "Guatemala",
    "Bahrain",
    "Grenada",
    "Uganda",
    "Sudan",
    "Ecuador",
    "Panama",
    "Eritrea",
    "Sri Lanka",
    "Mozambique",
    "Barbados"
];

