import React, { Fragment, useEffect } from 'react';
// import { useDispatch, useSelector } from 'react-redux';
import Loader from "../layout/loader";
import Header from '../layout/header';
import Sidebar from '../layout/sidebar';
import Footer from '../layout/footer';
import Startup from '../startup';
import ThemeCustomize from "../layout/theme-customizer";
import {ToastContainer} from 'react-toastify';
import {getMultipleLevelCategory} from '../services/subscriberService';
import {setCategories} from "../state/actions/categories";
import { subscriberRegistrationNo } from "../config.json";

const App = ({children}) => {
  // const dispatch = useDispatch();
  // const initialDataSetup= async()=>{
  //   const result =  await getMultipleLevelCategory(subscriberRegistrationNo);
  //   dispatch(setCategories(result.data[0]));
  // }
  // useEffect(()=>{
  //   initialDataSetup();
  // },[])
  console.warn = () => {}
  return (
    <Fragment>
      <Loader/>
      <Startup/>
      <div className="page-wrapper compact-wrapper" id="pageWrapper">
      <Header/>
      <div className="page-body-wrapper sidebar-icon">
        <Sidebar/>
        <div className="page-body">
          {children}
        </div>
        <Footer/>
        <ThemeCustomize/>
        </div>
      </div>
      <ToastContainer/>
    </Fragment>
  );
}

export default App;