import {
  SET_EMPLOYEES,
  SET_UPLOADED_STATUS,
  SET_SELECTED_EMPLOYEE,
  SET_SELECTED_EMPLOYEE_PICS,
  SET_SELECTED_BANNER_PICS_I,
  SET_SELECTED_BANNER_PICS_II,
} from "../actionTypes";

const initial_state = {
  employees: [],
};

export default (state = initial_state, action) => {
  switch (action.type) {
    case SET_EMPLOYEES:
      return {
        ...state,
        loading: false,
        uploadedstatus: false,
        employees: action.data,
      };
    case SET_SELECTED_EMPLOYEE:
      return { ...state, selectedemployee: action.data };
    case SET_SELECTED_EMPLOYEE_PICS:
      return { ...state, selectedemployeePicture: action.data };
    case SET_SELECTED_BANNER_PICS_I:
      return { ...state, selectedBannerPictureI: action.data };
    case SET_SELECTED_BANNER_PICS_II:
      return { ...state, selectedBannerPictureII: action.data };
    case SET_UPLOADED_STATUS:
      return { ...state, uploadedstatus: action.data };
    default:
      return { ...state };
  }
};
