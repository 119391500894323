import { SET_AFFAIRSTYPE } from "../actionTypes";

const initial_state = {
  affairstype: [],
};

export default (state = initial_state, action) => {
  switch (action.type) {
    case SET_AFFAIRSTYPE:
      return { ...state, loading: false, affairstype: action.data };

    default:
      return { ...state };
  }
};
