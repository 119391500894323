export class ConfigDB {
	static data = {
		info: {
			name: 'Salman Shaikh'
		},
		personalinfo: {
			dob: '18/02/1995',
			address: {
				area: 'Degloor Naka',
				city: 'Nanded'
			},
			sidebar_setting: 'default-sidebar',
		},
		officialinfo: {
			layout_version: 'light',
			color: 'color-1',
			primary_color: '#7366ff',
			secondary_color: '#f73164',
			mix_background_layout: 'light-only',
		},
		details:[
			{ make: "Tata", model: "Sumo", price: 35000, year: 2003 },
			{ make: "Maruti", model: "Wire", price: 32000, year: 2021 },
			{ make: "Suzuki", model: "Boxer 900 ", price: 72000, year: 2022 },
			{ make: "Kawasaki", model: "A4", price: 43000, year: 1987 }
		]
	}
}
export default ConfigDB;




