import http from "./httpService";
import { apiUrl } from "../config.json";

const apiEndpoint = apiUrl + "api/subscriber";
const apiEndpointCat = apiUrl + "api/subscriber/productcategory";

export function getAllSubscriber(subscriber) {
  return http.post(apiEndpoint + "/all", {
    json: subscriber,
  });
}
export function getMultipleLevelCategory(parentRegistrationId) {
  return http.post(apiEndpoint + "/multipleLevelCategory", {
    parentregistrationno: parentRegistrationId,
  });
}
export function getLoginSubscriber(subscriber) {
  const apiEndpointLogin = apiEndpoint + "/login";
  return http.post(apiEndpointLogin, {
    json: subscriber,
  });
}

export function getVerifyContactLoginSubscriber(subscriber) {
  const apiEndpointLogin = apiEndpoint + "/login";
  return http.post(apiEndpointLogin, {
    json: subscriber,
  });
}

export function register(subscriber) {
  return http.post(apiEndpoint, {
    json: subscriber,
  });
}

export function addEmployeeDetails(subscriber, originalname) {
  return http.post(apiEndpoint + "/addemployee", {
    json: subscriber,
    originalname: originalname,
  });
}

export function addAffairsDetails(subscriber) {
  return http.post(apiEndpoint + "/addaffairs", {
    json: subscriber,
  });
}

export function updateEmployeeDetails(subscriber, originalname) {
  return http.post(apiEndpoint + "/updateemployee", {
    json: subscriber,
    originalname: originalname,
  });
}

export function addHomeLevelDetails(subscriber, originalname) {
  return http.post(apiEndpoint + "/addhomeleveldetails", {
    json: subscriber,
    originalname: originalname,
  });
}

export function getProductCategories(ParentId) {
  return http.post(apiEndpointCat, {
    parentRegistrationNo: ParentId,
  });
}

export function getHomeDisplayProducts(ParentId) {
  return http.post(apiEndpoint + "/displayhomeproducts", {
    parentRegistrationNo: ParentId,
  });
}

export function getSendNotificationEmail(
  ParentId,
  templateCode,
  emailTo,
  nameTo,
  phoneTo,
  messageTo,
  countryTo,
  productTo
) {
  const apiEndpointProd = apiEndpoint + "/sendemail";
  return http.post(apiEndpointProd, {
    parentRegistrationNo: ParentId,
    templateCode: templateCode,
    emailTo: emailTo,
    nameTo: nameTo,
    phoneTo: phoneTo,
    messageTo: messageTo,
    countryTo: countryTo,
    productTo: productTo,
  });
}

/// Single Employee Details related api
export function getSingleEmployeesDetails(employeeId, registrationno) {
  const apiEndpointProd = apiUrl + "api/subscriber/singleemployeedetails";
  return http.post(apiEndpointProd, {
    employeeId,
    registrationno,
  });
}
/// Employee Details related api
export function getAffairs(affairstypeId, registrationno, inputSearchText) {
  const apiEndpointProd = apiUrl + "api/subscriber/affairs";
  return http.post(apiEndpointProd, {
    affairstypeId: affairstypeId,
    registrationno: registrationno,
    filtSearchText: inputSearchText,
  });
}
/// Employee Details related api
export function getEmployees(
  employeecategoryId,
  registrationno,

  inputSearchText
) {
  const apiEndpointProd = apiUrl + "api/subscriber/employees";
  return http.post(apiEndpointProd, {
    productcategoryId: employeecategoryId,
    registrationno: registrationno,
    filtSearchText: inputSearchText,
  });
}
export function getMembership(registrationno) {
  const apiEndpointProd = apiUrl + "api/subscriber/membership";
  return http.post(apiEndpointProd, {
    registrationno: registrationno,
  });
}
export function getDesignations(registrationno) {
  const apiEndpointProd = apiUrl + "api/subscriber/designationmaster";
  return http.post(apiEndpointProd, {
    registrationno: registrationno,
  });
}
export function getPrefix(registrationno) {
  const apiEndpointProd = apiUrl + "api/subscriber/prefixmaster";
  return http.post(apiEndpointProd, {
    registrationno: registrationno,
  });
}

export function getAffairstype(registrationno) {
  const apiEndpointProd = apiUrl + "api/subscriber/affairstypes";
  return http.post(apiEndpointProd, {
    registrationno: registrationno,
  });
}

export function getProducts(
  productcategoryId,
  registrationno,
  inputSearchText
) {
  const apiEndpointProd = apiUrl + "api/subscriber/products";
  return http.post(apiEndpointProd, {
    productcategoryId: productcategoryId,
    registrationno: registrationno,
    filtSearchText: inputSearchText,
  });
}
