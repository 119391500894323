import React, { Fragment, useState } from "react";
import { Card, CardHeader, CardBody } from "reactstrap";
import RichTextBox from "../../dashboard/richtextbox/";
import { Accordion } from "react-bootstrap";

export const AccordionWithOpenandCloseIconSingle = ({
  title,
  setContentData,
  contentData,
}) => {
  const [expanded1, setexpanded1] = useState(true);
  const Accordion1 = () => {
    setexpanded1(!expanded1);
  };

  return (
    <Fragment>
      <Card>
        <CardHeader>
          <h5 className="mb-0">
            <Accordion.Toggle
              as={Card.Header}
              className="btn btn-link txt-white "
              color="primary"
              onClick={Accordion1}
              eventKey="1"
              aria-expanded={expanded1}
            >
              {title}
            </Accordion.Toggle>
          </h5>
        </CardHeader>
        <Accordion.Collapse eventKey="1">
          <CardBody>
            <RichTextBox setContent={setContentData} content={contentData} />
          </CardBody>
        </Accordion.Collapse>
      </Card>
    </Fragment>
  );
};
