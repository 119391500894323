import React, { Fragment, useState } from "react";
import { useForm } from "react-hook-form";
import {
  Container,
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  Button,
  Form,
  FormGroup,
  Label,
  Input,
} from "reactstrap";
import { CKEditorExample } from "../../../constant";

const TextBoxWithLabel = ({ setContent, contentId, contentTitle }) => {
  const { register, handleSubmit, errors } = useForm();
  return (
    <Fragment>
      <Container>
        <Row>
          <Col md="12 mb-3">
            <Input
              className="form-control"
              id="validationCustom05"
              name={contentId}
              type="text"
              placeholder={contentTitle}
              innerRef={register({ required: true })}
              onBlur={setContent}
            />
            <span>{errors.exp && "Please provide a valid zip."}</span>
            <div className="invalid-feedback">
              {"Please provide a valid zip."}
            </div>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};

export default TextBoxWithLabel;
