import { combineReducers } from "redux";
import Customizer from "./customizer/reducer";
import Contacts from "./contacts/reducer";
import Categories from "./categories/reducer";
import Employees from "./employees/reducer";
import Genders from "./genders/reducer";
import Memberships from "./memberships/reducer";
import Designations from "./designations/reducer";
import Prefix from "./prefix/reducer";
import Affairs from "./affairs/reducer";

const reducers = combineReducers({
  Customizer,
  Contacts,
  Categories,
  Employees,
  Genders,
  Memberships,
  Designations,
  Prefix,
  Affairs,
});

export default reducers;
