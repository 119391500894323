import http from "./httpService";
import {
  apiUrl,
  imagesRepoUrl,
  profileImageFormat,
  bannerImageFormat,
  subscriberRegistrationNo,
} from "../config.json";
import axios from "axios";
import { setUploadedStatus } from "../state/actions/employees";
// import PostBox from "components/dashboard/default/PostBoxes";
const apiEndpoint = apiUrl + "api/userlogin";
export function register(user) {
  return http.post(apiEndpoint, {
    registrationno: user.registrationno,
    firstname: user.firstname,
    lastname: user.lastname,
    email: user.email,
    contact: user.contact,
    passwordsalt: user.passwordsalt,
    categoryid: user.categoryid,
    sociallinked: user.sociallinked,
  });
}

export function getUserInfo(user) {
  return http.post(apiEndpoint + "/login", {
    json: user,
  });
}

export function create_UUID() {
  var dt = new Date().getTime();
  var uuid = "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(
    /[xy]/g,
    function (c) {
      var r = (dt + Math.random() * 16) % 16 | 0;
      dt = Math.floor(dt / 16);
      return (c === "x" ? r : (r & 0x3) | 0x8).toString(16);
    }
  );
  return uuid;
}

export async function uploadMultipleFiles(file, req, dispatch) {
  const apiEndpoint = `${imagesRepoUrl}api/uploadmultiplefiles`;
  let fd = new FormData();
  fd.append(
    "file",
    file,
    `${req.parentregistrationno}_${req.fileType}_${req.registrationno}_${req.categoryI}_${req.categoryII}_${req.categoryIII}${req.multipleFormat}`
  );
  fd.append("name", req.registrationno);
  axios({
    url: apiEndpoint,
    method: "Post",
    headers: {
      "Content-Type": "multipart/form-data",
    },
    data: fd,
    onUploadProgress: (progressEvent) => {
      console.log(
        `Progress Upload : ${Math.round(
          (progressEvent.loaded / progressEvent.total) * 100
        )} %`
      );
      if ((progressEvent.loaded / progressEvent.total) * 100 === 100) {
        dispatch(setUploadedStatus(true));
      }
    },
  }).then(
    (res) => {
      return res;
    },
    (err) => {
      return err;
    }
  );
}
export async function uploadFiles(file, req, dispatch) {
  const apiEndpoint = `${imagesRepoUrl}api/uploadfiles`;
  let fd = new FormData();
  fd.append(
    "file",
    file,
    `${subscriberRegistrationNo}_${req.registrationno}${profileImageFormat}`
  );
  fd.append("name", req.registrationno);
  axios({
    url: apiEndpoint,
    method: "Post",
    headers: {
      "Content-Type": "multipart/form-data",
    },
    data: fd,
    onUploadProgress: (progressEvent) => {
      console.log(
        `Progress Upload : ${Math.round(
          (progressEvent.loaded / progressEvent.total) * 100
        )} %`
      );
      if ((progressEvent.loaded / progressEvent.total) * 100 === 100) {
        dispatch(setUploadedStatus(true));
      }
    },
  }).then(
    (res) => {
      return res;
    },
    (err) => {
      return err;
    }
  );
}
export async function uploadfilesupdate(file, req, dispatch) {
  const apiEndpoint = `${imagesRepoUrl}api/uploadfilesupdate`;
  let fd = new FormData();
  fd.append(
    "file",
    file,
    `${subscriberRegistrationNo}_${req.registrationno}_${req.filecount}${profileImageFormat}`
  );
  fd.append("name", req.registrationno);
  axios({
    url: apiEndpoint,
    method: "Post",
    headers: {
      "Content-Type": "multipart/form-data",
    },
    data: fd,
    onUploadProgress: (progressEvent) => {
      console.log(
        `Progress Upload : ${Math.round(
          (progressEvent.loaded / progressEvent.total) * 100
        )} %`
      );
      if ((progressEvent.loaded / progressEvent.total) * 100 === 100) {
        dispatch(setUploadedStatus(true));
      }
    },
  }).then(
    (res) => {
      return res;
    },
    (err) => {
      return err;
    }
  );
}
export async function uploadbannerfilesupdate(file, req, dispatch) {
  const apiEndpoint = `${imagesRepoUrl}api/uploadfilesupdate`;
  let fd = new FormData();
  fd.append(
    "file",
    file,
    `${subscriberRegistrationNo}_${req.registrationno}${req.selectedLabel}_${req.filecount}${bannerImageFormat}`
  );
  fd.append("name", req.registrationno);
  axios({
    url: apiEndpoint,
    method: "Post",
    headers: {
      "Content-Type": "multipart/form-data",
    },
    data: fd,
    onUploadProgress: (progressEvent) => {
      console.log(
        `Progress Upload : ${Math.round(
          (progressEvent.loaded / progressEvent.total) * 100
        )} %`
      );
      if ((progressEvent.loaded / progressEvent.total) * 100 === 100) {
        dispatch(setUploadedStatus(true));
      }
    },
  }).then(
    (res) => {
      return res;
    },
    (err) => {
      return err;
    }
  );
}
